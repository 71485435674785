import { VPPBillingAddress } from '@buy-viasat/redux/src/address';
import { encryptVPPMessage } from './encryptVPPMessage';

export function postVPPMessage(billingAddress: VPPBillingAddress) {
  const seed: string = Math.random().toString(36).substring(2, 10) + Math.random().toString(36).substring(2, 10);
  const vppIFrame: any = document.getElementById('vppIFrame');
  return vppIFrame.contentWindow.postMessage(
    JSON.stringify({
      vpsAttributes: {
        submitPaymentInfo: 'true',
        billingAddress: encryptVPPMessage(billingAddress, seed),
        useForRecurringPayment: 'true',
        savePaymentInfo: 'true',
        windowUrl: window.location.href,
        windowWidth: 370,
        windowHeight: 330,
      },
    }),
    '*',
  );
}
