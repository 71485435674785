import { VPPBillingAddress } from '@buy-viasat/redux/src/address';
import CryptoJS from 'crypto-js';
import env from 'env';

export function encryptVPPMessage(billingAddress: VPPBillingAddress, seed: string): string {
  const secret: any = CryptoJS.enc.Utf8.parse(env.vppIFrame.dataSecret);
  const base64Seed: string = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(seed));
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(billingAddress), secret, {
    iv: CryptoJS.enc.Base64.parse(base64Seed),
  });
  return seed + CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
}
