import {
  appActions,
  BR_RESIDENTIAL_SALES_FLOW_DEFINITION,
  CustomerType,
  IT_RESIDENTIAL_SALES_FLOW_DEFINITION,
  selectBuildVersion,
  selectCountry,
  selectCustomerType,
  selectFeatureFlags,
  selectLoadedFeatureFlags,
  selectLocale,
  US_BUSINESS_SALES_FLOW_DEFINITION,
  US_RESIDENTIAL_SALES_FLOW_DEFINITION,
} from '@buy-viasat/redux/src/app';
import { requestAccessToken, selectAccessToken, selectFlowId } from '@buy-viasat/redux/src/auth';
import {
  navActions,
  parseQueryString,
  Routes,
  selectCurrentAppRoute,
  selectMaxRoute,
  selectRoutesStates,
} from '@buy-viasat/redux/src/navigator';
import { addressActions } from '@buy-viasat/redux/src/address';
import { planActions } from '@buy-viasat/redux/src/plan';
import { selectIsServiceAdressSet, serviceabilityActions } from '@buy-viasat/redux/src/serviceability';
import { RESET_STATE_ACTION } from '@buy-viasat/redux/src/types';
import { Country, Product } from '@buy-viasat/types/build/bv';
import env from 'env';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import useAutoPublishViewChangesToExperimentationPlatform from 'shared/containers/Analytics/hooks/useAutoPublishViewChangesToExperimentationPlatform';
import { persistor } from '../store';
import { BeamFlowNavigator } from './Flows/BeamFlowNavigator';
import { LoadingSpinner, MaintenanceMode } from '@buy-viasat/react';
import { enableRaygun, setUser, trackPageView } from 'shared/utils/raygun';
import { paymentInformationActions } from '@buy-viasat/redux/src/payment-information';

const useDefaultLocale = (): string => {
  const { i18n } = useTranslation();
  return i18n.language;
};

/*
 * Need to know what country we are in.
 */
const TrackLocale = ({ location }: { location: any }): JSX.Element => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { locale } = useParams<{ locale: string }>();
  const currentCountry = useSelector(selectCountry);
  const currentLocale = useSelector(selectLocale);

  const validLocale = ((i18n.options?.supportedLngs as string[] | null) ?? ['en-US']).includes(locale);

  if (!validLocale) {
    return <Redirect to={{ ...location, pathname: '/' }} />;
  }

  if (currentLocale !== locale) {
    dispatch(RESET_STATE_ACTION);
    dispatch(appActions.setLocale(locale));
    return <Redirect to={{ ...location, pathname: '/' }} />;
  }

  const [, country] = locale.split('-');

  const validCountry = (country: Country): boolean => Country[country] !== undefined;
  if (validCountry(country as Country) && country !== currentCountry) {
    dispatch(appActions.setCountry(country as Country));
    return <Redirect to={{ ...location, pathname: `/${locale}` }} />;
  } else if (country !== currentCountry) {
    console.error('country does not match store, redirecting home');
    return <Redirect to={{ ...location, pathname: '/' }} />;
  } else if (!validCountry(country as Country)) {
    console.error(`Invalid country: ${country}`);
    return <Redirect to={{ ...location, pathname: '/' }} />;
  }

  return <></>;
};

const TrackCustomerType = ({ location }: { location: any }): JSX.Element => {
  const dispatch = useDispatch();
  const { locale, customerType } = useParams<{ locale: string; customerType: string }>();
  const currentCustomerType = useSelector(selectCustomerType);

  const isValidCustomerType = (customerType: string): customerType is CustomerType => {
    return Object.values(CustomerType).includes(customerType as CustomerType);
  };

  if (!isValidCustomerType(customerType)) {
    return <Redirect to={{ ...location, pathname: `/${locale}` }} />;
  }

  if (currentCustomerType !== customerType) {
    //if a user changes between SMB and Res via the Browser Back button, re-fetch plans
    dispatch(planActions.setHavePlansLoaded(false));

    dispatch(appActions.resetPartyId());
    dispatch(paymentInformationActions.clearBillingData());
    dispatch(appActions.setCustomerType(customerType));
    dispatch(planActions.setAvailableProducts([]));
    return <Redirect to={{ ...location, pathname: `/${locale}/${customerType}/` }} />;
  }

  return <></>;
};

const TrackCurrentStep = ({ location }: { location: any }): JSX.Element => {
  const currentRoute = useSelector(selectCurrentAppRoute);
  const routeStates = useSelector(selectRoutesStates);
  const maxRoute = useSelector(selectMaxRoute);
  const locale = useSelector(selectLocale);
  const customerType = useSelector(selectCustomerType);
  const { pathname } = useLocation();
  /*
   * Route user to currentWorkingRoute if they try to jump ahead
   */
  const pathArr = pathname.split('/');
  const urlRoute = pathArr[3];
  if (
    urlRoute &&
    routeStates[urlRoute as Routes] && // does route exist in routes?
    routeStates[maxRoute].order < routeStates[urlRoute as Routes].order && // is the route less than highest visited step?
    !env.flags.ignoreRouteOrder
  ) {
    return <Redirect to={{ ...location, pathname: `/${locale}/${customerType}/${currentRoute}` }} />;
  }
  return <></>;
};

export const Navigator = (): JSX.Element => {
  const dispatch = useDispatch();
  const location = useLocation();
  const accessToken = useSelector(selectAccessToken);
  const loadedFeatureFlags = useSelector(selectLoadedFeatureFlags);
  const customerType = useSelector(selectCustomerType);
  const buildVersion = useSelector(selectBuildVersion);
  const flowId = useSelector(selectFlowId);

  const { displayTooltipPlanGrid, maintenanceMode, shouldLogRaygunAnalytics } = useSelector(selectFeatureFlags);
  const history = useHistory();

  const country = useSelector(selectCountry);
  const isServiceAddressSet = useSelector(selectIsServiceAdressSet);

  useEffect(() => {
    if (shouldLogRaygunAnalytics || env.flags.shouldLogRaygunAnalyticsOverride) {
      enableRaygun(env.raygunApiKey);
    }
  }, []);

  useEffect(() => {
    if (flowId && (shouldLogRaygunAnalytics || env.flags.shouldLogRaygunAnalyticsOverride)) {
      setUser(flowId);
    }
  }, [flowId]);

  useEffect(() => {
    if (accessToken) parseQueryString(dispatch, location?.search ?? '', isServiceAddressSet);
    dispatch(appActions.startSession(persistor));
  }, [accessToken]);

  useEffect(() => {
    // set route based on URL
    const currentRoute = history.location.pathname.split('/')[3];
    dispatch(navActions.setCurrentAppRoute(currentRoute as Routes));
    dispatch(navActions.setupAppRoutes());
  });

  useEffect(() => {
    if (window.location?.pathname?.includes(country)) {
      dispatch(serviceabilityActions.setServiceAddressCountryCode(country)); //serviceAddress
      dispatch(serviceabilityActions.setScrubLocationCountryCode(country)); //scrubAddress
      dispatch(addressActions.setShippingCountryCode(country)); //shippingAddress
      dispatch(addressActions.setBillingCountryCode(country)); //billingAddress
      dispatch(appActions.setCountry(country)); //app
      switch (country) {
        case Country.BR:
          dispatch(appActions.setSalesFlowDefinition(BR_RESIDENTIAL_SALES_FLOW_DEFINITION));
          break;
        case Country.IT:
          dispatch(appActions.setSalesFlowDefinition(IT_RESIDENTIAL_SALES_FLOW_DEFINITION));
          break;
        default:
          if (customerType === CustomerType.BUSINESS) {
            dispatch(appActions.setSalesFlowDefinition(US_BUSINESS_SALES_FLOW_DEFINITION));
          } else {
            dispatch(appActions.setSalesFlowDefinition(US_RESIDENTIAL_SALES_FLOW_DEFINITION));
          }
          break;
      }
      dispatch(requestAccessToken({ env: env.env, authUrl: env.serverUrls.auth }));
    }
  }, [country, customerType]);

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  useEffect(() => {
    if (!buildVersion.updateRequired) {
      return;
    }

    if (buildVersion.updateRetryAttempts >= 1) {
      console.error('Build version reload max retries exceeded. Aborting reload.');
      return;
    }

    console.warn('Build version mismatch detected. Reloading page...');

    dispatch(appActions.setBuildVersionUpdateRequired(false));
    dispatch(appActions.setBuildVersionUpdateRetryAttempts(buildVersion.updateRetryAttempts + 1));

    // Wait for the state to be persisted before reloading the page
    persistor
      .flush()
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error occurred during state persistence:', error);
      });
  }, [buildVersion, dispatch, location]);

  /**
   * Setup analytics.
   */
  useAutoPublishViewChangesToExperimentationPlatform();

  const onPlanEdit = () => {
    dispatch(serviceabilityActions.resetServiceAddress());
    dispatch(navActions.routeUserTo(Routes.SERVICEABILITY));
  };

  const onPlanSelect = (product: Product) => {
    const isRegulated = product.characteristics.isRegulated === 'true';

    if (isRegulated) {
      dispatch(appActions.delayACPInfoVerification());
    }

    dispatch(
      planActions.planOnNext({
        isRegulated,
        id: product.id,
        name: product.name,
        planPrice: product.price,
        offerId: product.offerId || '',
        planPromo: product.promo?.price || 0,
        isCafII: product.isCafII || false,
        dataCap: product.characteristics.dataCap || '',
        extensionProducts: product.extensionTypes as string[],
        productFamily: product.characteristics.productFamily || '',
        inflectionPointText: product.characteristics.inflectionPointText || '',
      }),
    );
  };

  const onPlanDetails = () => {
    dispatch(navActions.routeUserTo(Routes.PLAN_DETAILS));
  };

  const defaultLocale = useDefaultLocale(); // Will be browser language or default to en-US

  return (
    <Switch>
      <Redirect exact from="/" to={{ ...location, pathname: `/${defaultLocale}` }} />
      <Redirect
        exact
        from={`/${CustomerType.RESIDENTIAL}`}
        to={{ ...location, pathname: `/${defaultLocale}/${CustomerType.RESIDENTIAL}/` }}
      />
      <Redirect
        exact
        from={`/${CustomerType.BUSINESS}`}
        to={{ ...location, pathname: `/${defaultLocale}/${CustomerType.BUSINESS}/` }}
      />
      <Redirect exact from="/:locale" to={{ ...location, pathname: `/:locale/${CustomerType.RESIDENTIAL}/` }} />
      <Route
        path="/:locale/:customerType"
        render={({ match: { url: baseURL } }): JSX.Element => {
          return (
            <>
              {/* Push :locale into redux */}
              {!loadedFeatureFlags && <LoadingSpinner my="112px" size="large" />}
              <TrackLocale location={location} />
              <TrackCustomerType location={location} />
              <TrackCurrentStep location={location} />

              {loadedFeatureFlags &&
                (maintenanceMode ? (
                  <MaintenanceMode />
                ) : (
                  <BeamFlowNavigator
                    baseURL={baseURL}
                    displayTooltipPlanGrid={displayTooltipPlanGrid}
                    onPlanEdit={onPlanEdit}
                    onPlanDetails={onPlanDetails}
                    onPlanSelect={onPlanSelect}
                  />
                ))}
            </>
          );
        }}
      />
      <Redirect to={{ ...location, pathname: `/${defaultLocale}` }} />
    </Switch>
  );
};
