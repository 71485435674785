import { AddressLine1, AddressLine2, selectSalesFlowDefinition } from '@buy-viasat/redux/src/app';
import { AddressInput } from '@buy-viasat/types/build/bv';
import { Button, ISurfaceProps, Surface, Txt } from '@vst/beam';
import { useSelector } from 'react-redux';
import styles from './addressSummary.module.scss';

type AddressSummaryProps = Omit<ISurfaceProps, 'ref'> & {
  title: string;
  subtitle: string;
  buttonText: string;
  address: AddressInput;
  onAddressUpdate: () => void;
};

export const AddressSummary = (props: AddressSummaryProps) => {
  const { title, subtitle, buttonText, address, onAddressUpdate, ...surfaceProps } = props;
  const { addressLine1FormatDisplay, addressLine2FormatDisplay } = useSelector(selectSalesFlowDefinition);
  const formattedAddressLine1 = formatAddressLine1(address.addressLines[0], addressLine1FormatDisplay);
  const formattedAddressLine2 = formatAddressLine2(
    address.municipality,
    address.region ?? '',
    address.postalCode,
    addressLine2FormatDisplay,
  );

  return (
    <Surface className={styles['wrapper']} {...surfaceProps}>
      <Surface className={styles['container']}>
        <Txt data-testid={'headlineServiceAddress'} color={'subtle'} variant="headerCapsSmall">
          {title}
        </Txt>

        <Surface data-testid={'addressText'} className={styles['addressText']} pt={'12px'}>
          <Txt variant="bodySmallRegular" color={'regular'} data-testid="addressLines">
            {formattedAddressLine1}{' '}
          </Txt>
          <Txt variant="bodySmallRegular" color={'regular'} pb={'12px'} data-testid="municipality">
            {formattedAddressLine2}
          </Txt>

          <Txt variant="smallRegular" component="span" color={'subtle'} data-testid="notAddress">
            {subtitle}
          </Txt>

          <Button
            data-testid={'updateButton'}
            variant={'tertiary'}
            onClick={onAddressUpdate}
            className={styles['button']}
            type={'button'}
            ml={'4px'}
            buttonSize={'small'}
          >
            {buttonText}
          </Button>
        </Surface>
      </Surface>
    </Surface>
  );
};

const formatAddressLine1 = (addressLine: string, addressLine1FormatDisplay: AddressLine1): string => {
  switch (addressLine1FormatDisplay) {
    case AddressLine1.US:
      return `${addressLine}`;
    default:
      return `${addressLine}`;
  }
};

const formatAddressLine2 = (
  municipality: string,
  region: string,
  postalCode: string,
  addressLine2FormatDisplay: AddressLine2,
): string => {
  switch (addressLine2FormatDisplay) {
    case AddressLine2.US:
      return `${municipality}, ${region} ${postalCode}`;
    default:
      return `${postalCode} ${municipality} ${region}`;
  }
};
