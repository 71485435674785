import { addressActions, AddressType, Coordinates, selectBillingAddressVpp } from '@buy-viasat/redux/src/address';
import { FormFieldState } from '@buy-viasat/redux/src/types';
import { selectEmail } from '@buy-viasat/redux/src/personal-information';
import { selectAccountHolderEmail } from '@buy-viasat/redux/src/business-information';
import {
  appActions,
  CustomerType,
  Features,
  Modals,
  selectCountry,
  selectCustomerType,
  selectFeatureFlags,
  selectHasVisitedOrderReview,
  selectPartyId,
  selectRelnId,
  selectSalesFlowDefinition,
} from '@buy-viasat/redux/src/app';
import { selectOnetimeTotalWithPromo } from '@buy-viasat/redux/src/cart';
import { navActions, Routes } from '@buy-viasat/redux/src/navigator';
import {
  AuthVoidTransaction,
  paymentInformationActions,
  QueryPaymentOnFile,
  selectIsPaymentInformationLoadingStartTime,
  selectNonRegulatedSpbBillingAccountId,
  selectRegulatedSpbBillingAccountId,
  selectSmbBillingAccountId,
  selectSpbBillingAccountId,
  selectTaxCode,
  selectTransactionId,
} from '@buy-viasat/redux/src/payment-information';
import { selectIsRegulated } from '@buy-viasat/redux/src/plan';
import {
  selectCoordinates,
  selectServiceAddressValues,
  serviceabilityActions,
} from '@buy-viasat/redux/src/serviceability';
import { Country, ErrorTypes, TaxCode } from '@buy-viasat/types/build/bv';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  CallEffect,
  delay,
  fork,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  takeLatest,
} from 'redux-saga/effects';
import { trackLoadTime } from 'shared/containers/Analytics';
import { benchmark } from 'shared/containers/App/saga';
import updateRelationshipInstanceAsync from 'shared/providers/requests/customer/updateRelationshipInstance';
import authAndVoidCardPaymentVerificationAsync, {
  AuthVoidResponse,
} from 'shared/providers/requests/payments/authAndVoidCardPaymentVerification';
import getPaymentOnFile from 'shared/providers/requests/payments/getPaymentOnFile';
import addBillingAccountAsync from '../../providers/requests/customer/addBillingAccount';
import updateBillingAccountAsync from '../../providers/requests/customer/updateBillingAccount';
import createVPPTransactionAsync from '../../providers/requests/payments/createVPPTransaction';
import getTaxCodesAsync from '../../providers/requests/payments/getTaxCodes';
import clientLogger from '../../utils/clientLogger';
import { personalInformationActions } from '../PersonalInformation/slice';
import { onOrderReviewButtonPress } from 'shared/Navigator/saga';
import { getBillingAccountIdToDeactivate } from 'shared/utils';
import { selectFlowId } from '@buy-viasat/redux/src/auth';
import { isDefinedString } from '@buy-viasat/utils';

const TWO_SECONDS_MILLIS = 2000;

export function* setLoadingFalseAfterDelay(): Generator {
  yield delay(TWO_SECONDS_MILLIS);
  yield put(paymentInformationActions.setIsPaymentInformationLoading(false));
}

export function* getVPPPaymentOnFileSaga(action: PayloadAction<QueryPaymentOnFile>): Generator {
  try {
    const response: any = yield call<any>(getPaymentOnFile, {
      systemName: action.payload.systemName,
      customerRef: action.payload.customerRef,
    });
    yield put(paymentInformationActions.setPaymentOnFileSuccess(response.data.getPaymentOnFile));
  } catch (err: any) {
    clientLogger('getVPPPaymentOnFileSaga Error: ', err);
    if (err.message === ErrorTypes.PaymentAuthError) yield put(appActions.setModalVisible(Modals.AUTH_ERROR));
  }
}

function* advanceToNextStep() {
  const { enablePlaceMyOrderFromPaymentPage } = (yield select(selectFeatureFlags)) as Features;
  const { shouldPlaceOrderFromPaymentPage } = yield select(selectSalesFlowDefinition);
  if (enablePlaceMyOrderFromPaymentPage && shouldPlaceOrderFromPaymentPage) {
    yield put(appActions.setIsPaymentInformationComplete(true));
    yield call(onOrderReviewButtonPress);
  } else {
    yield put(navActions.next());
  }
}

export function* createVPPAuthVoidTransactionSaga(
  action: PayloadAction<AuthVoidTransaction>,
): Generator<SelectEffect | CallEffect | PutEffect, void, string & number & AuthVoidResponse> {
  const relnId: any = yield select(selectRelnId);
  const spbBillingAccountId: any = yield select(selectSpbBillingAccountId);
  const transactionId: any = yield select(selectTransactionId);

  try {
    const { data }: AuthVoidResponse = yield call<any>(authAndVoidCardPaymentVerificationAsync, {
      relnId,
      oneTimeTotal: action.payload.authAmount,
      spbBillingAccountId: spbBillingAccountId,
      userAgent: navigator.userAgent,
      paymentOnFileId: action.payload.paymentOnFileId,
      transactionId,
    });
    if (data) {
      const { transactionId, authVoidSuccessful } = data.authAndVoidCardPaymentVerification;
      if (!transactionId || !authVoidSuccessful) {
        if (transactionId) yield put(paymentInformationActions.setTransactionId(transactionId));

        yield put(appActions.setIsCheckoutButtonDisabled(false));
        throw new Error(ErrorTypes.PaymentAuthError);
      }
      yield put(paymentInformationActions.setTransactionId(transactionId));

      const startTime = yield select(selectIsPaymentInformationLoadingStartTime);
      yield call(advanceToNextStep);
      const flowId = yield select(selectFlowId);
      trackLoadTime(startTime as number, performance.now(), action.type, flowId);
      yield put(paymentInformationActions.setIsPaymentInformationLoadingStartTime(0));
      yield put(paymentInformationActions.setIsPaymentInformationLoading(false));
    }
  } catch (err: any) {
    yield put(paymentInformationActions.setIsPaymentInformationLoading(false));
    clientLogger('createVPPAuthVoidTransactionSaga Error: ', err);
    yield put(appActions.setModalVisible(Modals.AUTH_ERROR));
    yield put(appActions.setIsCheckoutButtonDisabled(false));
  }
}

function* resetIframeTransaction() {
  yield delay(60 * 5 * 1000);
  const spbBillingAccountId: string = yield select(selectSpbBillingAccountId);
  yield call(createVPPTransactionSaga, {
    payload: spbBillingAccountId,
    type: 'paymentInformationActions.createVPPAuthVoidTransaction',
  });
}

export function* createVPPTransactionSaga(
  action: PayloadAction<string>,
): Generator<
  SelectEffect | CallEffect | PutEffect | ForkEffect,
  void,
  string & FormFieldState<string> & AddressType & number
> {
  const relnId: string = yield select(selectRelnId);
  const customerType: CustomerType = yield select(selectCustomerType);
  const billingAddress: AddressType = yield select(selectBillingAddressVpp);
  const oneTimeTotalWithPromo: number = yield select(selectOnetimeTotalWithPromo);
  const { shouldUseOneTimeTotalForVppTransaction } = yield select(selectSalesFlowDefinition);
  const { authAmount } = yield select(selectFeatureFlags);
  const transactionId = yield select(selectTransactionId);
  if (isDefinedString(transactionId) && transactionId === '') {
    yield put(paymentInformationActions.resetTransactionId());
  }

  let email = '';

  switch (customerType) {
    case CustomerType.BUSINESS:
      email = yield select(selectAccountHolderEmail);
      break;
    case CustomerType.RESIDENTIAL:
      const emailField: FormFieldState<string> = yield select(selectEmail);
      email = emailField.value;
      break;
  }

  const oneTimeTotal = oneTimeTotalWithPromo === 0 ? authAmount : oneTimeTotalWithPromo;

  try {
    const createVPPResult: any = yield call<any>(createVPPTransactionAsync, {
      relnId,
      email: email,
      billingAddress: billingAddress,
      oneTimeTotal: shouldUseOneTimeTotalForVppTransaction ? oneTimeTotal : 0,
      spbBillingAccountId: action.payload,
      userAgent: navigator.userAgent,
    });
    const resultTransactionId: string = createVPPResult.data.createVPPTransaction.transactionId;
    yield put(paymentInformationActions.setTransactionId(resultTransactionId));
    yield fork(setLoadingFalseAfterDelay);

    yield fork(resetIframeTransaction);
  } catch (err: any) {
    yield put(paymentInformationActions.setIsPaymentInformationLoading(false));
    clientLogger('createVPPTransactionSaga Error: ', err);
  }
}

type GetBillingAccountsToRemoveParam = {
  regulatedSPBBillingAccountId?: string;
  nonRegulatedSPBBillingAccountId?: string;
  smbBillingAccountId?: string;
};

function getBillingAccountsToRemove(accountIdTypes: GetBillingAccountsToRemoveParam): string[] {
  const values = Object.values(accountIdTypes);
  const result = values.filter((value) => !!value);
  return result;
}

function* clearBillingDataSaga() {
  yield put(paymentInformationActions.setSpbBillingAccountId(''));
  yield put(paymentInformationActions.setSmbBillingAccountId(''));
  yield put(paymentInformationActions.setTransactionId(''));
  yield put(paymentInformationActions.setRegulatedSpbBillingAccountId(''));
  yield put(paymentInformationActions.setNonRegulatedSpbBillingAccountId(''));
}

export function* getTaxCodesSaga() {
  let billingAccountId: string = yield select(selectSpbBillingAccountId);
  const taxCode: TaxCode = yield select(selectTaxCode);
  const serviceAddress: AddressType = yield select(selectServiceAddressValues);
  const coordinates: Coordinates = yield select(selectCoordinates);
  const isRegulated: boolean = yield select(selectIsRegulated);
  const regulatedSPBBillingAccountId: string = yield select(selectRegulatedSpbBillingAccountId);
  const nonRegulatedSPBBillingAccountId: string = yield select(selectNonRegulatedSpbBillingAccountId);
  const smbBillingAccountId: string = yield select(selectSmbBillingAccountId);
  const customerType: string = yield select(selectCustomerType);
  const hasVisitedOrderReview: boolean = yield select(selectHasVisitedOrderReview);

  try {
    const { data } = yield call<any>(getTaxCodesAsync, { address: serviceAddress, coordinates });
    const taxCodes: TaxCode[] = data.getTaxCodes;
    const isTaxCodeDifferent =
      taxCode.taxCodeType != taxCodes?.[0].taxCodeType || taxCode.taxCodeValue != taxCodes?.[0].taxCodeValue;

    if (isTaxCodeDifferent) {
      yield put(paymentInformationActions.setTaxCode(taxCodes[0]));
    }

    if (isRegulated) {
      if (isDefinedString(regulatedSPBBillingAccountId)) {
        billingAccountId = regulatedSPBBillingAccountId;
        yield put(paymentInformationActions.setSpbBillingAccountId(billingAccountId));
        if (isTaxCodeDifferent) {
          yield put(paymentInformationActions.updateBillingAccount());
        } else {
          yield put(paymentInformationActions.createVPPTransaction(billingAccountId));
          if (hasVisitedOrderReview) {
            yield put(appActions.setHasVisitedOrderReview(false));
          }
        }
        yield call(updateRelationshipInstanceSaga);
      } else {
        const accountIdTypes = {
          smbBillingAccountId,
          nonRegulatedSPBBillingAccountId,
        };
        const result = getBillingAccountsToRemove(accountIdTypes);
        yield put(paymentInformationActions.addBillingAccount(result));
      }
    } else if (customerType === CustomerType.BUSINESS) {
      billingAccountId = smbBillingAccountId;
      if (isDefinedString(smbBillingAccountId)) {
        yield put(paymentInformationActions.setSpbBillingAccountId(billingAccountId));
        if (isTaxCodeDifferent) {
          yield put(paymentInformationActions.updateBillingAccount());
        } else {
          yield put(paymentInformationActions.createVPPTransaction(billingAccountId));
          if (hasVisitedOrderReview) {
            yield put(appActions.setHasVisitedOrderReview(false));
          }
        }
        yield call(updateRelationshipInstanceSaga);
      } else {
        const accountIdTypes = {
          regulatedSPBBillingAccountId,
          nonRegulatedSPBBillingAccountId,
        };
        const result = getBillingAccountsToRemove(accountIdTypes);
        yield put(paymentInformationActions.addBillingAccount(result));
      }
    } else {
      if (isDefinedString(nonRegulatedSPBBillingAccountId)) {
        billingAccountId = nonRegulatedSPBBillingAccountId;
        yield put(paymentInformationActions.setSpbBillingAccountId(billingAccountId));
        if (isTaxCodeDifferent) {
          yield put(paymentInformationActions.updateBillingAccount());
        } else {
          yield put(paymentInformationActions.createVPPTransaction(billingAccountId));
          if (hasVisitedOrderReview) {
            yield put(appActions.setHasVisitedOrderReview(false));
          }
        }
        yield call(updateRelationshipInstanceSaga);
      } else {
        const accountIdTypes = {
          regulatedSPBBillingAccountId,
          smbBillingAccountId,
        };
        const result = getBillingAccountsToRemove(accountIdTypes);
        yield put(paymentInformationActions.addBillingAccount(result));
      }
    }
  } catch (err: any) {
    yield put(appActions.setErrorRetryAction({ type: paymentInformationActions.getTaxCodes.type, payload: null }));
    yield put(personalInformationActions.onSagaError({ callingSaga: 'getTaxCodesSaga', err, payload: null }));
    yield put(appActions.setModalVisible(Modals.ERROR));
  }
}

function* onEditBillingAddressSaga() {
  const country: Country = yield select(selectCountry);

  yield put(appActions.setIsCartVisible(false));
  yield put(paymentInformationActions.setIsPaymentInformationLoading(false));
  yield put(addressActions.setBillingNotEqualToServiceAddress());
  yield put(addressActions.resetBillingAddress(country));
  yield put(appActions.setIsModalVisible(false));
  yield put(navActions.routeUserTo(Routes.PERSONAL_INFORMATION));
}

export function* updateRelationshipInstanceSaga(): Generator {
  const relnId: any = yield select(selectRelnId);
  const spbBillingAccountId: any = yield select(selectSpbBillingAccountId);
  const regulatedSPBBillingAccountId: any = yield select(selectRegulatedSpbBillingAccountId);
  const nonRegulatedSPBBillingAccountId: any = yield select(selectNonRegulatedSpbBillingAccountId);
  const smbBillingAccountId: any = yield select(selectSmbBillingAccountId);

  const deactivateBillingAccountArg = {
    nonRegulatedSPBBillingAccountId,
    smbBillingAccountId,
    regulatedSPBBillingAccountId,
    spbBillingAccountId,
  };
  const deactivateAccountId = getBillingAccountIdToDeactivate(deactivateBillingAccountArg);
  try {
    const data: any = yield call<any>(updateRelationshipInstanceAsync, {
      relnId,
      accountNumber: spbBillingAccountId,
      accountNumbersToRemove: deactivateAccountId,
    });
  } catch (err: any) {
    yield put(
      personalInformationActions.onSagaError({ callingSaga: 'updateRelationshipInstanceSaga', err, payload: null }),
    );
    yield put(appActions.setErrorRetryAction({ type: serviceabilityActions.scrubServiceAddress.type, payload: null }));
    yield put(appActions.setModalVisible(Modals.ERROR));
  }
}

function* addSPBBillingAccountSaga(action: PayloadAction<string>): Generator {
  const taxCode: any = yield select(selectTaxCode);
  const relnId: any = yield select(selectRelnId);
  const partyId: any = yield select(selectPartyId);
  const isRegulated = yield select(selectIsRegulated);
  const customerType = yield select(selectCustomerType);
  const hasVisitedOrderReview = yield select(selectHasVisitedOrderReview);

  const { taxCodeType, taxCodeValue } = taxCode;
  try {
    const {
      data: {
        addBillingAccount: { accountNumber },
      },
    }: any = yield call<any>(addBillingAccountAsync, {
      relnId,
      partyId,
      /*
       * We Pass ACH here as Default as we won't know the payment type till after payment step,
       *  but want to send SPB Account to Payment Transaction
       */
      recurringPaymentMethodType: 'Visa',
      taxCode: {
        taxCodeType,
        taxCodeValue,
      },
      isRegulated,
      accountNumbersToRemove: action.payload,
    });

    yield put(paymentInformationActions.setSpbBillingAccountId(accountNumber));

    if (isRegulated) {
      yield put(paymentInformationActions.setRegulatedSpbBillingAccountId(accountNumber));
    } else if (customerType === CustomerType.BUSINESS) {
      yield put(paymentInformationActions.setSmbBillingAccountId(accountNumber));
    } else {
      yield put(paymentInformationActions.setNonRegulatedSpbBillingAccountId(accountNumber));
    }
    yield put(paymentInformationActions.createVPPTransaction(accountNumber));
    if (hasVisitedOrderReview) {
      yield put(appActions.setHasVisitedOrderReview(false));
    }
  } catch (err: any) {
    yield put(
      appActions.setErrorRetryAction({ type: paymentInformationActions.addBillingAccount.type, payload: null }),
    );
    yield put(personalInformationActions.onSagaError({ callingSaga: 'addSPBBillingAccountSaga', err, payload: null }));
    yield put(appActions.setModalVisible(Modals.ERROR));
  }
}

function* updateBillingAccountSaga(): Generator {
  const billingAccountId: any = yield select(selectSpbBillingAccountId);
  const taxCode: any = yield select(selectTaxCode);
  const hasVisitedOrderReview = yield select(selectHasVisitedOrderReview);

  const { taxCodeType, taxCodeValue } = taxCode;
  try {
    const {
      data: {
        updateBillingAccount: { accountNumber },
      },
    }: any = yield call<any>(updateBillingAccountAsync, {
      accountNumber: billingAccountId,
      taxCode: {
        taxCodeType,
        taxCodeValue,
      },
    });
    yield put(paymentInformationActions.createVPPTransaction(accountNumber));
    if (hasVisitedOrderReview) {
      yield put(appActions.setHasVisitedOrderReview(false));
    }
  } catch (err: any) {
    yield put(
      appActions.setErrorRetryAction({ type: paymentInformationActions.updateBillingAccount.type, payload: null }),
    );
    yield put(personalInformationActions.onSagaError({ callingSaga: 'updateBillingAccountSaga', err, payload: null }));
    yield put(appActions.setModalVisible(Modals.ERROR));
  }
}

export function* vppPostMessageSuccessSaga(): Generator {
  const startTime = yield select(selectIsPaymentInformationLoadingStartTime);
  yield call(advanceToNextStep);
  const flowId = yield select(selectFlowId);
  trackLoadTime(
    startTime as number,
    performance.now(),
    paymentInformationActions.setVppPostMessageSuccess.type,
    flowId as string,
  );
  yield put(paymentInformationActions.setIsPaymentInformationLoadingStartTime(0));
}

export function* vppPostMessageFailureSaga(): Generator {
  const startTime = yield select(selectIsPaymentInformationLoadingStartTime);
  const flowId = yield select(selectFlowId);
  trackLoadTime(
    startTime as number,
    performance.now(),
    paymentInformationActions.setVPPPostMessageFailure.type,
    flowId as string,
  );
  yield put(paymentInformationActions.setIsPaymentInformationLoadingStartTime(0));
}

/**
 * Root saga manages watcher lifecycle
 */
export function* paymentInformationSaga(): Generator {
  yield takeLatest(paymentInformationActions.createVPPTransaction.type, benchmark(createVPPTransactionSaga));
  yield takeLatest(paymentInformationActions.setPaymentOnFileRequested.type, benchmark(getVPPPaymentOnFileSaga));
  yield takeLatest(paymentInformationActions.getTaxCodes.type, benchmark(getTaxCodesSaga));
  yield takeLatest(paymentInformationActions.clearBillingData.type, clearBillingDataSaga);
  yield takeLatest(paymentInformationActions.addBillingAccount.type, benchmark(addSPBBillingAccountSaga));
  yield takeLatest(paymentInformationActions.updateBillingAccount.type, benchmark(updateBillingAccountSaga));
  yield takeLatest(paymentInformationActions.setVppPostMessageSuccess.type, vppPostMessageSuccessSaga);
  yield takeLatest(paymentInformationActions.setVPPPostMessageFailure.type, vppPostMessageFailureSaga);
  yield takeLatest(paymentInformationActions.onEditBillingAddress.type, benchmark(onEditBillingAddressSaga));
  yield takeLatest(
    paymentInformationActions.createVPPAuthVoidTransaction.type,
    benchmark(createVPPAuthVoidTransactionSaga),
  );
}
