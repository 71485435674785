export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID?: string;
  String?: string;
  Boolean?: boolean;
  Int?: number;
  Float?: number;
  Date?: () => any;
  DateTime?: () => any;
  LocalDateTime?: () => any;
};

export enum ActionEnum {
  ExtendCartItem = 'EXTEND_CART_ITEM',
  AddPortfolio = 'ADD_PORTFOLIO',
  ExtendPortfolioItem = 'EXTEND_PORTFOLIO_ITEM',
  ReplacePortfolioItem = 'REPLACE_PORTFOLIO_ITEM',
  AddCartItem = 'ADD_CART_ITEM',
  RemoveCartItem = 'REMOVE_CART_ITEM',
  UpdateStatus = 'UPDATE_STATUS',
}

export type AddAddressInput = {
  primary?: () => Maybe<AddressInput>;
  secondary?: () => Maybe<AddressInput>;
  invoice?: () => Maybe<AddressInput>;
  shipping?: () => Maybe<AddressInput>;
  support?: () => Maybe<AddressInput>;
};

export type AddBillingAccountInput = {
  recurringPaymentMethodType?: Scalars['String'];
  relnId?: Scalars['ID'];
  taxCode?: () => TaxCodeInput;
  partyId?: Scalars['ID'];
  isRegulated?: Maybe<Scalars['Boolean']>;
  accountNumbersToRemove?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  isBusinessAccount?: Maybe<Scalars['Boolean']>;
  isEndUserBilled?: Maybe<Scalars['Boolean']>;
  isExemptFromLateFee?: Maybe<Scalars['Boolean']>;
  isExemptFromTaxation?: Maybe<Scalars['Boolean']>;
};

export enum AddContactMethodError {
  Address = 'Address',
  Email = 'Email',
  IdInputRequired = 'IdInputRequired',
  Phone = 'Phone',
}

export type AddEmailInput = {
  primary?: Maybe<Scalars['String']>;
  Secondary?: Maybe<Scalars['String']>;
  Invoice?: Maybe<Scalars['String']>;
  Support?: Maybe<Scalars['String']>;
};

export type AddOn = {
  __typename?: string;
  id?: Scalars['String'];
  name?: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  subHeading?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  price?: Scalars['Float'];
  promo?: () => ProductPromo;
  selected?: Maybe<Scalars['Boolean']>;
  shortName?: Maybe<Scalars['String']>;
  noAddAttributes?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  attributes?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  annualSavings?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  crossOuts?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  disclosure?: Maybe<Scalars['String']>;
  offerId?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  isBundle?: Maybe<Scalars['Boolean']>;
};

export type AddPhoneInput = {
  primary?: Maybe<Scalars['String']>;
  secondary?: Maybe<Scalars['String']>;
  invoice?: Maybe<Scalars['String']>;
  support?: Maybe<Scalars['String']>;
};

export type AddRelationshipInstanceInput = {
  relnTypeName?: Scalars['ID'];
  groups?: Array<Scalars['String']>;
  roles?: () => Array<PartyRoleInput>;
};

export type AddRelnEditInput = {
  destinationId?: Scalars['ID'];
  destinationType?: ProductIdType;
  sourceType?: ProductIdType;
  relnType?: Scalars['String'];
  sourceId?: Scalars['ID'];
};

export type AddRelnEditR0 = {
  destinationId?: Scalars['String'];
  destinationType?: Scalars['String'];
  relnType?: Scalars['String'];
  sourceType?: Scalars['String'];
};

export enum AddonType {
  EasyCare = 'EasyCare',
  Voice = 'Voice',
  Shield = 'Shield',
  StreamOn = 'StreamOn',
  WorkFromHome = 'WorkFromHome',
  StaticIp = 'StaticIp',
}

export type AddressCsc = {
  __typename?: string;
  addressLines?: Array<Scalars['String']>;
  municipality?: Scalars['String'];
  region?: Scalars['String'];
  countryCode?: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
};

export type AddressCscr0 = {
  addressLines?: Array<Scalars['String']>;
  municipality?: Scalars['String'];
  region?: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type AddressDetailsPayload = {
  __typename?: string;
  addressDetails?: () => Maybe<FilteredAddressSuggestion>;
  status?: GooglePlacesStatusCode;
};

export type AddressGeometry = {
  __typename?: string;
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
};

export type AddressInput = {
  addressLines?: Array<Scalars['String']>;
  countryCode?: Scalars['String'];
  municipality?: Scalars['String'];
  postalCode?: Scalars['String'];
  region?: Maybe<Scalars['String']>;
};

export type AddressInputFo = {
  addressLine?: Array<Scalars['String']>;
  municipality?: Scalars['String'];
  region?: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  countryCode?: Scalars['String'];
};

export type AddressInputOm = {
  addressLines?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  municipality?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  countryCode?: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  houseNumber?: Maybe<Scalars['String']>;
};

export type AddressInputPartners = {
  addressLines?: Array<Scalars['String']>;
  municipality?: Scalars['String'];
  region?: Scalars['String'];
  postalCode?: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
};

export type AddressInputR0 = {
  addressLines?: Array<Scalars['String']>;
  municipality?: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  countryCode?: Scalars['String'];
  postalCode?: Scalars['String'];
};

export type AddressR0 = {
  __typename?: string;
  addressLines?: Array<Scalars['String']>;
  municipality?: Scalars['String'];
  region?: Scalars['String'];
  country?: Maybe<Scalars['String']>;
  postalCode?: Scalars['String'];
  countryCode?: Maybe<Scalars['String']>;
};

export type AddressSuggestion = {
  __typename?: string;
  matchedSubstrings?: () => Array<MatchedSubstring>;
  placeId?: Scalars['String'];
  description?: Scalars['String'];
};

export type AddressSuggestionsPayload = {
  __typename?: string;
  suggestions?: () => Array<Maybe<AddressSuggestion>>;
  status?: GooglePlacesStatusCode;
};

export type AemErrorParams = {
  errorCode?: Maybe<Scalars['String']>;
};

export type Amount = {
  __typename?: string;
  value?: Maybe<Scalars['Float']>;
  currency?: () => Maybe<Array<Maybe<CurrencyType>>>;
};

export type Appointment = {
  startTime?: Scalars['String'];
  endTime?: Scalars['String'];
};

export type Attribute = {
  __typename?: string;
  name?: Scalars['String'];
  value?: Scalars['String'];
  valueType?: Maybe<Scalars['String']>;
};

export type AttributeInputFo = {
  name?: Scalars['String'];
  value?: Scalars['String'];
};

export type AttributeInputR0 = {
  name?: Scalars['String'];
  value?: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type AttributeR0 = {
  __typename?: string;
  name?: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AuthAndVoidCardPaymentVerificationInput = {
  spbBillingAccountId?: Maybe<Scalars['String']>;
  txnAmount?: Maybe<Scalars['Int']>;
  userAgent?: Maybe<Scalars['String']>;
  customerRef?: Maybe<Scalars['String']>;
  paymentOnFileId?: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
};

export type AvailableAppointmentsPayloadR0 = {
  __typename?: string;
  timezone?: Maybe<Scalars['String']>;
  schedules?: () => Array<Maybe<ScheduleR0>>;
};

export type BehaviorClass = {
  __typename?: string;
  behavior?: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type BillingAccount = {
  __typename?: string;
  accountNumber?: Scalars['ID'];
  accountGroupNumber?: Scalars['ID'];
  invoicingOrganization?: () => InvoicingOrganization;
  paymentReference?: () => PaymentReference;
  recurringPaymentMethodType?: Maybe<Scalars['String']>;
  billingPIIRefId?: Scalars['ID'];
  mailingPIIRefId?: Maybe<Scalars['ID']>;
  piiFileLocationId?: Scalars['ID'];
  nextBillPeriodStartDate?: Maybe<Scalars['Date']>;
  billingCycleDayOfMonth?: Maybe<Scalars['Int']>;
  currentBalance?: () => Maybe<Money>;
  totalAmountUnbilledOneTimeCharges?: () => Maybe<Money>;
  totalAmountUnbilledAdjustments?: () => Maybe<Money>;
  totalAmountPendingDisputes?: () => Maybe<Money>;
  accountStatus?: Maybe<Scalars['String']>;
  totalAmountPastDue?: () => Maybe<Money>;
  totalDaysPastDue?: Maybe<Scalars['Int']>;
  balanceForward?: () => Maybe<Money>;
  isBusinessAccount?: Maybe<Scalars['Boolean']>;
  isExemptFromLateFee?: Maybe<Scalars['Boolean']>;
  isExemptFromTaxation?: Maybe<Scalars['Boolean']>;
  taxCode?: () => Maybe<TaxCode>;
};

export type BillingAccountResponse = {
  __typename?: string;
  billingAccount?: () => Maybe<BillingAccount>;
};

export type BusinessUnit = {
  __typename?: string;
  businessUnitId?: Scalars['ID'];
  businessUnitName?: Maybe<Scalars['String']>;
};

export type CartCharacteristics = {
  __typename?: string;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CartItems = {
  __typename?: string;
  plan?: () => Maybe<CartProduct>;
  addons?: () => Array<Maybe<CartProduct>>;
  closingOffers?: () => Maybe<Array<Maybe<CartProduct>>>;
};

export type CartMarketingCopy = {
  __typename?: string;
  uiBehaviors?: () => Maybe<Array<Maybe<CartUiBehaviors>>>;
  translations?: () => Maybe<Array<CartTranslations>>;
};

export type CartPackageTypes = {
  __typename?: string;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  productTypes?: () => Array<CartProductTypes>;
};

export type CartProduct = {
  __typename?: string;
  categoryName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productCandidateId?: Maybe<Scalars['String']>;
  productTypeId?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  promo?: () => Maybe<Promo>;
  accountSetupFeeShortName?: Maybe<Scalars['String']>;
  accountSetupFeePrice?: Maybe<Scalars['Float']>;
  contractTerms?: Maybe<Scalars['String']>;
  contractTermsDisclosure?: Maybe<Scalars['String']>;
  leaseFeeShortName?: Maybe<Scalars['String']>;
  leaseFeePrice?: Maybe<Scalars['Float']>;
};

export type CartProductTypes = {
  __typename?: string;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  marketingCopy?: () => Maybe<Array<CartMarketingCopy>>;
};

export type CartTranslations = {
  __typename?: string;
  characteristics?: () => Maybe<Array<CartCharacteristics>>;
};

export type CartUiBehaviors = {
  __typename?: string;
  characteristics?: () => Array<Maybe<CartCharacteristics>>;
};

export type Characteristic = {
  __typename?: string;
  name?: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  valueType?: Maybe<Scalars['String']>;
};

export type CharacteristicInput = {
  name?: Scalars['String'];
  value?: Scalars['String'];
  valueType?: Maybe<Scalars['String']>;
};

export type ContactMethodFilterInput = {
  address?: () => Maybe<OptionalAddressInput>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
};

export enum ContactMethodLabel {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Invoice = 'Invoice',
  Shipping = 'Shipping',
  Support = 'Support',
}

export type Coordinates = {
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
};

export type CoordinatesInput = {
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
};

export type CoordinatesInputFo = {
  latitude?: Scalars['Float'];
  longitude?: Scalars['Float'];
};

export type CreateContractInstance = {
  __typename?: string;
  contractId?: Maybe<Scalars['String']>;
  customerId?: Scalars['String'];
  productInstanceId?: Maybe<Scalars['String']>;
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  phoneNumber?: Scalars['String'];
  email?: Scalars['String'];
  addressLine1?: Scalars['String'];
  addressLine2?: Scalars['String'];
  contractTemplateId?: Scalars['String'];
  inviteSigner?: Maybe<Scalars['Boolean']>;
  isAccepted?: Maybe<Scalars['Boolean']>;
};

export enum CreateCustomerError {
  AddIndividual = 'AddIndividual',
  AddOrganization = 'AddOrganization',
  AddressInputRequired = 'AddressInputRequired',
  RelationshipId = 'RelationshipId',
}

export type CreateCustomerInput = {
  individual?: () => Maybe<CreateIndividualInput>;
  organization?: () => Maybe<CreateOrganizationInput>;
};

export type CreateCustomerResponse = {
  __typename?: string;
  isCustomer?: Scalars['Boolean'];
  existingContactMethod?: Maybe<ExistingContactMethod>;
  individualPartyId?: Scalars['String'];
  organizationPartyId?: Scalars['String'];
  relnId?: Scalars['String'];
};

export type CreateIndividualInput = {
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  email?: Scalars['String'];
  phoneNumber?: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  serviceAddress?: () => AddressInput;
  billingAddress?: () => Maybe<AddressInput>;
  shippingAddress?: () => Maybe<AddressInput>;
  taxId?: Maybe<Scalars['String']>;
};

export type CreateOrganizationInput = {
  organizationName?: Scalars['String'];
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  email?: Scalars['String'];
  phoneNumber?: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  serviceAddress?: () => AddressInput;
  billingAddress?: () => Maybe<AddressInput>;
  shippingAddress?: () => Maybe<AddressInput>;
  taxId?: Maybe<Scalars['String']>;
  isTaxIdSsn?: Maybe<Scalars['Boolean']>;
  isTaxExempt?: Maybe<Scalars['Boolean']>;
};

export enum CreateSalesOrderError {
  CreateShoppingCart = 'CreateShoppingCart',
  SalesOrder = 'SalesOrder',
  Timeout = 'Timeout',
  WorkOrder = 'WorkOrder',
}

export type CreateSalesOrderInput = {
  cartId?: Scalars['ID'];
  customerRelationshipId?: Scalars['String'];
  productIds?: Maybe<Array<Scalars['String']>>;
  workOrder?: () => Maybe<WorkOrderInput>;
};

export type CreateSalesOrderPayloadR0 = {
  __typename?: string;
  order?: () => Maybe<Order>;
  workOrder?: () => Maybe<ScheduleWorkOrderPayloadR0>;
};

export enum CreditCheckError {
  NoResultsFound = 'NoResultsFound',
  InternalServerError = 'InternalServerError',
}

export type Currency = {
  __typename?: string;
  alphabeticCode?: Maybe<Scalars['String']>;
  numericCode?: Maybe<Scalars['Float']>;
  minorUnits?: Maybe<Scalars['Int']>;
};

export type CurrencyType = {
  __typename?: string;
  name?: Maybe<Scalars['String']>;
};

export type Customer = {
  __typename?: string;
  isCustomer?: Scalars['Boolean'];
  existingContactMethod?: Maybe<ExistingContactMethod>;
  partyId?: Scalars['String'];
  relnId?: Scalars['String'];
};

export type CustomerInfoMarketing = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  serviceAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type CustomerParams = {
  dealerId?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
};

export type DataAccessGroup = {
  __typename?: string;
  groupName?: Scalars['ID'];
  description?: Scalars['String'];
};

export type DateOfBirth = {
  month?: Scalars['String'];
  day?: Scalars['String'];
  year?: Scalars['String'];
};

export type DigitalService = {
  __typename?: string;
  iconUrl?: Maybe<Scalars['String']>;
};

export type DiscountType = {
  __typename?: string;
  totalDiscounts?: () => Maybe<Array<Maybe<TotalDiscount>>>;
};

export type EditInstallationDateInput = {
  externalWorkOrderId?: Scalars['ID'];
  schedule?: () => ScheduleInput;
};

export enum EditTypeR0 {
  AddReln = 'AddReln',
  ReplaceProdCandidateEdit = 'ReplaceProdCandidateEdit',
  RmProdCandidate = 'RmProdCandidate',
  RmReln = 'RmReln',
}

export enum ErrorTypes {
  PaymentAuthError = 'PAYMENT_AUTH_ERROR',
}

export enum ExistingContactMethod {
  Address = 'address',
  Email = 'email',
  PhoneNumber = 'phoneNumber',
}

export type ExternalId = {
  __typename?: string;
  type?: () => ExternalIdType;
  value?: Scalars['String'];
};

export type ExternalIdType = {
  __typename?: string;
  description?: Scalars['String'];
  groups?: () => Array<DataAccessGroup>;
  pattern?: Maybe<Scalars['String']>;
  typeName?: Scalars['String'];
};

export type FeatureFlags = {
  __typename?: string;
  singleFieldAddress?: Maybe<Scalars['Boolean']>;
  allowChoosePlan?: Maybe<Scalars['Boolean']>;
  serviceabilityAptSte?: Maybe<Scalars['Boolean']>;
  showFooter?: Maybe<Scalars['Boolean']>;
  scheduleInstallLocation?: Maybe<Scalars['String']>;
  onSiteContactInformation?: Maybe<Scalars['Boolean']>;
  billingContactInformation?: Maybe<Scalars['Boolean']>;
  videoQuality?: Maybe<Scalars['Boolean']>;
  displayPromoBannerWindow?: Maybe<Scalars['Boolean']>;
  displayAddonsPage?: Maybe<Scalars['Boolean']>;
  displayNewPlanGrid?: Maybe<Scalars['Boolean']>;
  isSSNMandatory?: Maybe<Scalars['Boolean']>;
  authAmount?: Maybe<Scalars['Int']>;
  boletoExpirationDays?: Maybe<Scalars['Int']>;
  displayBanner?: Maybe<Scalars['Boolean']>;
  displayPlansBanner?: Maybe<Scalars['Boolean']>;
  enableMarketingConsent?: Maybe<Scalars['Boolean']>;
  placeMyOrderButton?: Maybe<Scalars['Boolean']>;
  displayGiftCardBanner?: Maybe<Scalars['Boolean']>;
  showShippingAddress?: Maybe<Scalars['Boolean']>;
  sellingPoint?: Maybe<Scalars['String']>;
  displayViewMoreDetailsLink?: Maybe<Scalars['Boolean']>;
  topBarOptions?: Maybe<Scalars['String']>;
  displayPhoneNumber?: Maybe<Scalars['Boolean']>;
  displayHighDataCapDiscountCartBanner?: Maybe<Scalars['Boolean']>;
  hideScrubAddressModal?: Maybe<Scalars['Boolean']>;
  allowChooseVoice?: Maybe<Scalars['Boolean']>;
  displayTooltipPlanGrid?: Maybe<Scalars['Boolean']>;
  displayCreditCheckPage?: Maybe<Scalars['Boolean']>;
  pricePlanCard?: Maybe<Scalars['String']>;
  displayHouseNumber?: Maybe<Scalars['Boolean']>;
  signupMyViasat?: Maybe<Scalars['Boolean']>;
  displayRemarketingCheckbox?: Maybe<Scalars['Boolean']>;
  displayNextSteps?: Maybe<Scalars['Boolean']>;
  workOrderCharacterLimit?: Maybe<Scalars['Int']>;
  enableDishPartner?: Maybe<Scalars['Boolean']>;
  dishNetworkPartnerInfo?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  shouldDisplayDueToday?: Maybe<Scalars['Boolean']>;
  displaySmbLink?: Maybe<Scalars['Boolean']>;
  displayNewPlanCards?: Maybe<Scalars['Boolean']>;
  maintenanceMode?: Maybe<Scalars['Boolean']>;
  displayAcpBeforeCreditCheck?: Maybe<Scalars['Boolean']>;
  enablePlaceMyOrderFromPaymentPage?: Maybe<Scalars['Boolean']>;
  displayTotalSavingsTag?: Maybe<Scalars['Boolean']>;
  shouldLogRaygunAnalytics?: Maybe<Scalars['Boolean']>;
  skeletonLoaderForContactInformation?: Maybe<Scalars['Boolean']>;
  enableIraNewEndpoint?: Maybe<Scalars['Boolean']>;
  strictPhoneNumberValidation?: Maybe<Scalars['Boolean']>;
  hideAcpCheckbox?: Maybe<Scalars['Boolean']>;
  displayingTooltipOnBvPlanCards?: Maybe<Scalars['Boolean']>;
  enablingClosingOffersInBV?: Maybe<Scalars['Boolean']>;
  enableBroadbandLabels?: Maybe<Scalars['Boolean']>;
  displayButtonLabels?: Maybe<Scalars['Boolean']>;
};

export type FeatureFlagsInput = {
  userKey?: Maybe<Scalars['ID']>;
  isPartner?: Maybe<Scalars['Boolean']>;
  customerParams?: () => Maybe<CustomerParams>;
};

export type FeesAndDiscounts = {
  __typename?: string;
  name?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  kind?: Scalars['String'];
};

export type FilteredAddressSuggestion = {
  __typename?: string;
  formattedAddress?: Scalars['String'];
  geometry?: () => AddressGeometry;
  structuredAddress?: () => AddressCsc;
};

export type FilteredAddressesPayload = {
  __typename?: string;
  suggestions?: () => Array<Maybe<FilteredAddressSuggestion>>;
  status?: GooglePlacesStatusCode;
};

export type FulfillmentAgreement = {
  __typename?: string;
  fulfillmentAgreementId?: Scalars['ID'];
  fulfillmentAgreementName?: Scalars['String'];
  salesPartner?: () => SalesPartner;
  fulfillmentPolicy?: () => FulfillmentPolicy;
  masterAgent?: () => MasterAgent;
  businessUnit?: () => BusinessUnit;
  attributes?: () => Maybe<Array<Attribute>>;
};

export type FulfillmentIncentivePolicy = {
  __typename?: string;
  fulfillmentIncentivePolicyId?: Scalars['ID'];
  workOrderType?: () => WorkOrderType;
  productKinds?: () => Array<ProductKind>;
};

export type FulfillmentPolicy = {
  __typename?: string;
  fulfillmentPolicyId?: Scalars['ID'];
  fulfillmentPolicy?: Scalars['String'];
  fulfillmentRoutingPolicy?: () => FulfillmentRoutingPolicy;
  fulfillmentIncentivePolicies?: () => Array<FulfillmentIncentivePolicy>;
  fulfillmentTerritory?: () => FulfillmentTerritory;
  territoryDefault?: Scalars['Boolean'];
};

export type FulfillmentRoutingPolicy = {
  __typename?: string;
  fulfillmentRoutingPolicyId?: Scalars['ID'];
  fulfillmentRoutingPolicy?: Scalars['String'];
  fulfillmentSystem?: () => FulfillmentSystem;
};

export type FulfillmentSystem = {
  __typename?: string;
  fulfillmentSystemId?: Scalars['ID'];
  fulfillmentSystem?: Scalars['String'];
};

export type FulfillmentTerritory = {
  __typename?: string;
  fulfillmentTerritoryId?: Scalars['ID'];
  fulfillmentTerritory?: Scalars['String'];
  defaultFulfillmentPolicy?: () => FulfillmentPolicy;
};

export type GetAddOnsInput = {
  location?: () => LocationInput;
  rootProductId?: Scalars['ID'];
  dealerId?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  offerId?: Maybe<Scalars['String']>;
  salesAgreementId?: Scalars['String'];
  productSegment?: Maybe<Scalars['String']>;
};

export type GetAvailableAppointments = {
  from?: Scalars['String'];
  to?: Scalars['String'];
  address_lines?: Scalars['String'];
  municipality?: Scalars['String'];
  region?: Scalars['String'];
  country_code?: Scalars['String'];
  postal_code?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  fulfillment_agreement_id?: Maybe<Scalars['String']>;
  root_product_instance_id?: Maybe<Scalars['String']>;
  shopping_cart_id?: Maybe<Scalars['String']>;
};

export type GetAvailableExtensionProductsInputR0 = {
  salesAgreementId?: Maybe<Scalars['String']>;
  location?: () => LocationInput;
  rootProductId?: Scalars['String'];
  productRelationship?: Scalars['String'];
  productSegment?: Maybe<Scalars['String']>;
};

export enum GetAvailableProductsError {
  Fulfillment = 'Fulfillment',
  Product = 'Product',
}

export type GetAvailableProductsInputR0 = {
  salesAgreementId?: Maybe<Scalars['String']>;
  location?: () => LocationInput;
  creditCheckResults?: () => Maybe<Array<Maybe<AttributeInputR0>>>;
  additionalAttributes?: () => Maybe<Array<Maybe<AttributeInputR0>>>;
  customerPartyId?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  dealerId?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  productSegment?: Maybe<Scalars['String']>;
  customerType?: Maybe<Scalars['String']>;
};

export type GetAvailableWorkOrderSchedulesFilters = {
  from?: Scalars['LocalDateTime'];
  to?: Scalars['LocalDateTime'];
};

export type GetAvailableWorkOrderSchedulesInput = {
  serviceLocation?: () => LocationInputFo;
  fulfillmentAgreementId?: Scalars['ID'];
  products?: () => Array<ThinProductInput>;
};

export type GetAvailableWorkOrderSchedulesPayload = {
  __typename?: string;
  schedules?: () => Maybe<Array<Schedule>>;
  timezone?: Scalars['String'];
};

export type GetClosingOffersInput = {
  location?: () => LocationInput;
  rootProductId?: Scalars['String'];
  offerId?: Maybe<Scalars['String']>;
  salesAgreementId?: Maybe<Scalars['String']>;
  productIds?: Array<Scalars['String']>;
};

export type GetCurrentCustomerRelationshipsIdName = {
  __typename?: string;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GetCurrentCustomerRelationshipsInputR0 = {
  productInstanceId?: Scalars['String'];
};

export type GetCurrentCustomerRelationshipsProductFulfillmentAgreement = {
  __typename?: string;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  fulfillmentPolicy?: () => Maybe<GetCurrentCustomerRelationshipsIdName>;
  selfFulfilling?: Maybe<Scalars['Boolean']>;
};

export type GetCurrentCustomerRelationshipsResponse = {
  __typename?: string;
  productInstanceId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  relnId?: Maybe<Scalars['String']>;
  salesAgreementId?: Maybe<Scalars['String']>;
  lifecycleState?: Maybe<Scalars['String']>;
  salesAgreement?: () => GetCurrentCustomerRelationshipsSalesAgreeement;
  productFulfillmentAgreement?: () => Maybe<GetCurrentCustomerRelationshipsProductFulfillmentAgreement>;
};

export type GetCurrentCustomerRelationshipsSalesAgreeement = {
  __typename?: string;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  salesPolicy?: () => Maybe<GetCurrentCustomerRelationshipsSalesPolicy>;
  salesPartner?: () => Maybe<GetCurrentCustomerRelationshipsIdName>;
  masterAgent?: () => Maybe<GetCurrentCustomerRelationshipsIdName>;
  salesRelationshipType?: Maybe<Scalars['String']>;
  selfFulfilling?: Maybe<Scalars['Boolean']>;
  dealerId?: Maybe<Scalars['String']>;
};

export type GetCurrentCustomerRelationshipsSalesPolicy = {
  __typename?: string;
  businessUnit?: () => Maybe<GetCurrentCustomerRelationshipsIdName>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type GetCustomerPayload = {
  __typename?: string;
  isCustomer?: Scalars['Boolean'];
  existingContactMethod?: Maybe<ExistingContactMethod>;
};

export type GetCustomerResult = {
  __typename?: string;
  fullName?: Maybe<Scalars['String']>;
  emails?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  addresses?: () => Maybe<Array<Maybe<AddressCsc>>>;
  phoneNumbers?: () => Maybe<Array<Maybe<Scalars['String']>>>;
};

export type GetFulfillmentAgreementsInput = {
  fulfillmentAgreementId?: Maybe<Scalars['ID']>;
  fulfillmentAgreementName?: Maybe<Scalars['String']>;
  salesPartnerId?: Maybe<Scalars['ID']>;
  salesPartnerName?: Maybe<Scalars['String']>;
  fulfillmentPolicyId?: Maybe<Scalars['ID']>;
  fulfillmentPolicyName?: Maybe<Scalars['String']>;
  masterAgentId?: Maybe<Scalars['ID']>;
  masterAgentName?: Maybe<Scalars['String']>;
  businessUnitId?: Maybe<Scalars['ID']>;
  businessUnitName?: Maybe<Scalars['String']>;
  attributes_contains?: () => Maybe<Array<AttributeInputFo>>;
  location?: () => Maybe<LocationInputFo>;
};

export type GetFulfillmentAgreementsPayload = {
  __typename?: string;
  fulfillmentAgreements?: () => Maybe<Array<FulfillmentAgreement>>;
};

export type GetInstallationDatesBvInput = {
  address?: () => Maybe<AddressCscr0>;
  from?: Maybe<Scalars['String']>;
};

export type GetInstallationDatesInputR0 = {
  cartId?: Scalars['ID'];
  location?: () => Maybe<LocationInput>;
  from?: Scalars['String'];
  to?: Maybe<Scalars['String']>;
  dealerId?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  salesAgreementId?: Scalars['String'];
  fulfillmentAgreementId?: Scalars['String'];
};

export type GetInstallationFeeR0 = {
  customerPartyId?: Scalars['ID'];
  location?: () => LocationInput;
  creditCheckResults?: () => Array<AttributeInputR0>;
  productId?: Scalars['String'];
  cartId?: Scalars['ID'];
  dealerId?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  subsidyApplicationId?: Maybe<Scalars['String']>;
  salesAgreementId?: Scalars['String'];
};

export type GetOrderStateInput = {
  orderId?: Scalars['ID'];
};

export type GetPartnerAccountInputR0 = {
  location?: () => LocationInputR0;
  businessUnitPartyId?: Maybe<Scalars['String']>;
  personaRole?: Maybe<Scalars['String']>;
  partnerIraPartyId?: Maybe<Scalars['String']>;
  isReferral?: Maybe<Scalars['String']>;
  dealerId?: Maybe<Scalars['String']>;
};

export type GetPaymentOnFileInput = {
  systemName?: Maybe<Scalars['String']>;
  customerRef?: Maybe<Scalars['String']>;
};

export type GetPaymentOnFilePayload = {
  __typename?: string;
  last4Digits?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  ccExpYear?: Maybe<Scalars['Int']>;
  ccExpMonth?: Maybe<Scalars['Int']>;
};

export type GetWorkOrderInput = {
  externalWorkOrderId?: Scalars['String'];
};

export type GetWorkOrderPayload = {
  __typename?: string;
  status?: Scalars['String'];
};

export enum GooglePlacesStatusCode {
  Ok = 'OK',
  ZeroResults = 'ZERO_RESULTS',
  OverQueryLimit = 'OVER_QUERY_LIMIT',
  RequestDenied = 'REQUEST_DENIED',
  InvalidRequest = 'INVALID_REQUEST',
  UnknownError = 'UNKNOWN_ERROR',
}

export enum Group {
  Gbs = 'GBS',
  UsResidential = 'USResidential',
  UsBusiness = 'USBusiness',
  BrazilResidential = 'BrazilResidential',
  ItalyResidential = 'ItalyResidential',
  EuResidential = 'EUResidential',
}

export enum InputAttributeName {
  Ssn = 'SSN',
  Dob = 'DOB',
  Country = 'COUNTRY',
}

export type InstallationDatesResponse = {
  __typename?: string;
  timezone?: Maybe<Scalars['String']>;
  schedules?: () => Maybe<Array<Maybe<ScheduleR0>>>;
};

export type InstallationFeeR0 = {
  __typename?: string;
  value?: Maybe<Scalars['Float']>;
};

export type InvoicingOrganization = {
  __typename?: string;
  invoicingOrgId?: Scalars['ID'];
  name?: Scalars['String'];
  description?: Scalars['String'];
};

export type ItemDiscount = {
  __typename?: string;
  discountItem?: () => Maybe<Promo>;
};

export type LocationInput = {
  coordinates?: () => Maybe<CoordinatesInput>;
  address?: () => Maybe<AddressInputOm>;
};

export type LocationInputFo = {
  coordinates?: () => Maybe<CoordinatesInputFo>;
  address?: () => Maybe<AddressInputFo>;
};

export type LocationInputR0 = {
  coordinates?: () => Maybe<CoordinatesInput>;
  address?: () => AddressInputPartners;
};

export type LogUrlParamsResponse = {
  __typename?: string;
  urlParamsLogged?: Maybe<Scalars['Boolean']>;
};

export type MarketingCopy = {
  __typename?: string;
  name?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  characteristics?: () => Maybe<Array<Maybe<AttributeR0>>>;
};

export type MasterAgent = {
  __typename?: string;
  masterAgentId?: Scalars['ID'];
  masterAgentName?: Maybe<Scalars['String']>;
};

export type MatchedSubstring = {
  __typename?: string;
  length?: Scalars['Int'];
  offset?: Scalars['Int'];
};

export type Money = {
  __typename?: string;
  value?: Maybe<Scalars['Float']>;
  currency?: () => Maybe<Currency>;
};

export type Mutation = {
  __typename?: string;
  addBillingAccount?: () => Maybe<BillingAccount>;
  addBillingAccountAndCustReln?: () => Maybe<RelationshipInstance>;
  addContactMethods?: () => Success;
  addRelationshipInstance?: () => Maybe<RelationshipInstance>;
  createCustomer?: () => Maybe<CreateCustomerResponse>;
  createIndividual?: () => Maybe<Customer>;
  createOrganization?: () => Maybe<SmbCustomer>;
  createSalesOrder?: () => CreateSalesOrderPayloadR0;
  editInstallationDate?: Scalars['Boolean'];
  removeCartItems?: () => Maybe<ShoppingCart>;
  sendLeadToMarketo?: () => Maybe<SendLeadToMarketoResponse>;
  submitFullOrder?: () => Maybe<SubmitFullOrderPayload>;
  submitOrder?: () => Maybe<Order>;
  updateBillingAccount?: () => Maybe<BillingAccount>;
  updateIndividual?: () => Maybe<UpdateIndividualResponse>;
  updateMarketingPreferences?: () => Maybe<UpdateMarketingPreferencesResponse>;
  updateOrganization?: () => Maybe<UpdateOrganizationResponse>;
  updateRelationshipInstance?: () => Maybe<BillingAccount>;
  upsertCartAddons?: () => Maybe<ShoppingCart>;
  upsertCartPlan?: () => Maybe<ShoppingCart>;
};

export type MutationAddBillingAccountArgs = {
  input?: () => Maybe<AddBillingAccountInput>;
};

export type MutationAddBillingAccountAndCustRelnArgs = {
  relnId?: Scalars['ID'];
  setStringSetAttrs?: () => Maybe<Array<StringSetAttributeInput>>;
};

export type MutationAddContactMethodsArgs = {
  input?: () => UpdateContactMethodInput;
};

export type MutationAddRelationshipInstanceArgs = {
  relnTypeName?: Scalars['ID'];
  groups?: Array<Scalars['String']>;
  roles?: () => Array<PartyRoleInput>;
};

export type MutationCreateCustomerArgs = {
  input?: () => CreateCustomerInput;
};

export type MutationCreateIndividualArgs = {
  input?: () => CreateIndividualInput;
};

export type MutationCreateOrganizationArgs = {
  input?: () => CreateOrganizationInput;
};

export type MutationCreateSalesOrderArgs = {
  input?: () => CreateSalesOrderInput;
};

export type MutationEditInstallationDateArgs = {
  input?: () => EditInstallationDateInput;
};

export type MutationRemoveCartItemsArgs = {
  input?: () => RemoveCartItems;
};

export type MutationSendLeadToMarketoArgs = {
  input?: () => Maybe<CustomerInfoMarketing>;
};

export type MutationSubmitFullOrderArgs = {
  input?: () => Maybe<SubmitFullOrderInput>;
};

export type MutationSubmitOrderArgs = {
  input?: () => OrderInput;
};

export type MutationUpdateBillingAccountArgs = {
  input?: () => UpdateBillingAccountInput;
};

export type MutationUpdateIndividualArgs = {
  input?: () => UpdateIndividualInput;
};

export type MutationUpdateMarketingPreferencesArgs = {
  input?: () => Maybe<UpdateMarketingPreferences>;
};

export type MutationUpdateOrganizationArgs = {
  input?: () => UpdateOrganizationInput;
};

export type MutationUpdateRelationshipInstanceArgs = {
  input?: () => Maybe<UpdateRelationshipIdInput>;
};

export type MutationUpsertCartAddonsArgs = {
  input?: () => ShoppingCartAddonsInput;
};

export type MutationUpsertCartPlanArgs = {
  input?: () => ShoppingCartPlanInput;
};

export type NladBenefitsInput = {
  partyId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['String']>;
  applicationId?: Maybe<Scalars['String']>;
  transactionType?: Maybe<Scalars['String']>;
  tribalBenefit?: Maybe<Scalars['Boolean']>;
};

export type NladBenefitsResponse = {
  __typename?: string;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
  correctiveAction?: Maybe<Scalars['String']>;
  traceId?: Maybe<Scalars['String']>;
};

export type NoteInputR0 = {
  content?: Scalars['String'];
  author?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['String']>;
};

export type NoteR0 = {
  __typename?: string;
  content?: Scalars['String'];
  author?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['String']>;
};

export type NullableAddress = {
  __typename?: string;
  addressLine?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  municipality?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type OfmAddress = {
  addressLines?: () => Array<Maybe<Scalars['String']>>;
  municipality?: Scalars['String'];
  region?: Scalars['String'];
  countryCode?: Scalars['String'];
  postalCode?: Scalars['String'];
};

export type OfmLocation = {
  coordinates?: () => Coordinates;
  address?: () => OfmAddress;
};

export type OfmPackageTypes = {
  __typename?: string;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  packageTypes?: () => Array<CartPackageTypes>;
  productTypes?: () => Maybe<Array<PackageProductTypes>>;
  characteristics?: () => Maybe<Array<CartCharacteristics>>;
  marketingCopy?: () => Maybe<Array<CartMarketingCopy>>;
};

export type OnSiteContactInformation = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type OptionalAddressInput = {
  addressLines?: Maybe<Array<Scalars['String']>>;
  municipality?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: string;
  orderId?: Scalars['ID'];
  state?: Maybe<OrderLifecycleState>;
};

export type OrderAttributeInput = {
  valueType?: Maybe<Scalars['String']>;
  name?: Scalars['String'];
  value?: Scalars['String'];
};

export type OrderInput = {
  orderId?: Scalars['ID'];
  customerRelationshipId?: Scalars['ID'];
  salesAgreementId?: Maybe<Scalars['ID']>;
  fulfillmentAgreementId?: Maybe<Scalars['ID']>;
  orderLines?: () => Maybe<Array<OrderLineItemInput>>;
  shoppingCartId?: Scalars['ID'];
  state?: Maybe<OrderLifecycleState>;
  productInstanceIds?: Maybe<Array<Scalars['ID']>>;
  productCandidateGraphEdits?: () => Maybe<Array<ProductCandidateGraphEditInput>>;
  validationSignature?: Maybe<Scalars['String']>;
  paymentTransactionId?: Maybe<Scalars['ID']>;
  executionDate?: Maybe<Scalars['DateTime']>;
  serviceLocation?: () => Maybe<LocationInput>;
  /** OrderAttributes can consist of arbitrary business unit specific information that is opaque to OM. */
  attributes?: () => Maybe<Array<OrderAttributeInput>>;
};

export type OrderInputR0 = {
  orderId?: Maybe<Scalars['String']>;
  customerRelationshipId?: Scalars['String'];
  productCandidateGraphEdits?: () => Maybe<Array<Maybe<ProductCandidateGraphEditR0>>>;
  orderLines?: () => Maybe<Array<Maybe<OrderLineInputR0>>>;
  attributes?: () => Maybe<Array<Maybe<AttributeInputR0>>>;
  serviceLocation?: () => Maybe<ServiceLocationR0>;
  paymentTransactionId?: Maybe<Scalars['String']>;
  executionDate?: Maybe<Scalars['String']>;
  shoppingCartId?: Maybe<Scalars['String']>;
};

export enum OrderLifecycleState {
  Canceled = 'Canceled',
  Canceling = 'Canceling',
  CancelingError = 'CancelingError',
  Closed = 'Closed',
  Initializing = 'Initializing',
  InitializingError = 'InitializingError',
  Open = 'Open',
  OpenError = 'OpenError',
  Processed = 'Processed',
  ProcessedError = 'ProcessedError',
  Rejected = 'Rejected',
  Scheduled = 'Scheduled',
}

export enum OrderLineEvent {
  Add = 'Add',
  Return = 'Return',
  Transition = 'Transition',
}

export enum OrderLineEventR0 {
  Add = 'Add',
  Return = 'Return',
  Transition = 'Transition',
}

export type OrderLineInputR0 = {
  orderLineId?: Scalars['String'];
  orderLineEvent?: Scalars['String'];
  productInstanceId?: Maybe<Scalars['String']>;
  shoppingCartItemId?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  orderLineItemId?: Maybe<Scalars['String']>;
  characteristics?: () => Maybe<Array<Maybe<AttributeInputR0>>>;
};

export type OrderLineItemInput = {
  productInstanceId?: Maybe<Scalars['ID']>;
  orderLineId?: Maybe<Scalars['ID']>;
  orderLineItemId?: Maybe<Scalars['ID']>;
  orderLineEvent?: OrderLineEvent;
  orderLineAttributes?: () => Maybe<Array<OrderAttributeInput>>;
  shoppingCartItemId?: Maybe<Scalars['ID']>;
  characteristics?: () => Maybe<Array<Maybe<CharacteristicInput>>>;
  serviceLocation?: () => Maybe<LocationInput>;
  contractId?: Maybe<Scalars['ID']>;
};

export type OrderLineR0 = {
  __typename?: string;
  orderLineId?: Scalars['String'];
  orderLineEvent?: Scalars['String'];
  productInstanceId?: Maybe<Scalars['String']>;
  shoppingCartItemId?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  orderLineItemId?: Maybe<Scalars['String']>;
  attributes?: () => Maybe<Array<Maybe<AttributeR0>>>;
  characteristics?: () => Maybe<Array<Maybe<AttributeR0>>>;
  state?: Maybe<Scalars['String']>;
};

export type OrderR0 = {
  __typename?: string;
  orderId?: Scalars['String'];
  customerRelationshipId?: Scalars['String'];
  productInstanceIds?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  serviceLocationId?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  attributes?: () => Maybe<Array<Maybe<AttributeR0>>>;
  orderLines?: () => Maybe<Array<Maybe<OrderLineR0>>>;
};

export type PackageAmount = {
  __typename?: string;
  value?: Maybe<Scalars['String']>;
  currency?: () => Maybe<PackageCurrency>;
};

export type PackageCurrency = {
  __typename?: string;
  name?: Maybe<Scalars['String']>;
  alphabeticCode?: Maybe<Scalars['String']>;
};

export type PackagePrices = {
  __typename?: string;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  amount?: () => Maybe<Array<PackageAmount>>;
  recurrence?: Maybe<Scalars['String']>;
};

export type PackageProductTypes = {
  __typename?: string;
  id?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  marketingCopy?: () => Maybe<Array<ProductMarketingCopy>>;
  prices?: () => Maybe<Array<PackagePrices>>;
};

export type PackageType = {
  __typename?: string;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  minSelections?: Maybe<Scalars['Int']>;
  maxSelections?: Maybe<Scalars['Int']>;
  productTypes?: () => Maybe<Array<Maybe<ProductTypeR0>>>;
  marketingCopy?: () => Maybe<Array<Maybe<MarketingCopy>>>;
  characteristics?: () => Maybe<Array<Maybe<AttributeR0>>>;
  serviceTypes?: () => Maybe<Array<Maybe<ServiceType>>>;
  packageTypes?: () => Maybe<Array<Maybe<PackageType>>>;
};

export type PartnerAccountResponse = {
  __typename?: string;
  salesAgreementId?: Maybe<Scalars['String']>;
  fulfillmentAgreementId?: Maybe<Scalars['String']>;
};

export type PartyRoleInput = {
  partyId?: Scalars['ID'];
  roleName?: Scalars['String'];
};

export enum PartyType {
  Individual = 'Individual',
  Organization = 'Organization',
}

export type PaymentReference = {
  __typename?: string;
  value?: Scalars['String'];
  type?: () => PaymentReferenceType;
};

export type PaymentReferenceType = {
  __typename?: string;
  name?: Scalars['ID'];
  description?: Scalars['String'];
};

export type PerformCreditCheckInput = {
  partyId?: Scalars['String'];
  ssn?: Scalars['String'];
  dob?: () => DateOfBirth;
  serviceLocation?: () => LocationInput;
  productId?: Scalars['String'];
  cartId?: Scalars['ID'];
  taxId?: Maybe<Scalars['String']>;
  dealerId?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  subsidyApplicationId?: Maybe<Scalars['String']>;
  salesAgreementId?: Scalars['String'];
};

export type PerformCreditCheckResponse = {
  __typename?: string;
  installationFee?: Maybe<Scalars['Float']>;
  isEligible?: Maybe<Scalars['Boolean']>;
  equifaxTransactionId?: Maybe<Scalars['String']>;
  creditTag?: Maybe<Scalars['String']>;
  feesAndDiscounts?: () => Maybe<Array<FeesAndDiscounts>>;
};

export type PlanCharacteristics = {
  __typename?: string;
  dataCapUnits?: Maybe<Scalars['String']>;
  dataCap?: Maybe<Scalars['String']>;
  uploadSpeed?: Maybe<Scalars['String']>;
  uploadUnits?: Maybe<Scalars['String']>;
  downloadSpeed?: Maybe<Scalars['String']>;
  downloadSpeedText?: Maybe<Scalars['String']>;
  downloadUnits?: Maybe<Scalars['String']>;
  displayOrder?: Maybe<Scalars['Int']>;
  freeZone?: Maybe<Scalars['String']>;
  resolution?: Maybe<Scalars['String']>;
  productFamily?: Maybe<Scalars['String']>;
  textGeneral?: Maybe<Scalars['String']>;
  textGeneral01?: Maybe<Scalars['String']>;
  inflectionPointText?: Maybe<Scalars['String']>;
  dataAllowanceText?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['String']>;
  titleAddOns?: Maybe<Scalars['String']>;
  isRegulated?: Maybe<Scalars['String']>;
  routerText?: Maybe<Scalars['String']>;
  attribute1?: Maybe<Scalars['String']>;
  attribute2?: Maybe<Scalars['String']>;
  benefits?: Maybe<Scalars['String']>;
  bannerColor?: Maybe<Scalars['String']>;
  serviceType?: Maybe<Scalars['String']>;
  imageOneUrl?: Maybe<Scalars['String']>;
  contractTerm?: Maybe<Scalars['String']>;
  contractTermUnit?: Maybe<Scalars['String']>;
  feeText?: Maybe<Scalars['String']>;
  downloadRange?: Maybe<Scalars['String']>;
  uploadSpeedText?: Maybe<Scalars['String']>;
  typicalDownloadSpeed?: Maybe<Scalars['String']>;
  upi?: Maybe<Scalars['String']>;
  serviceAreaId?: Maybe<Scalars['String']>;
};

export type Price = {
  __typename?: string;
  name?: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  kind?: Scalars['String'];
  recurrence?: Scalars['String'];
  amount?: () => Money;
  characteristics?: () => Maybe<Array<Maybe<Characteristic>>>;
};

export type Product = {
  __typename?: string;
  id?: Scalars['String'];
  name?: Scalars['String'];
  characteristics?: () => PlanCharacteristics;
  price?: Scalars['Float'];
  extensionTypes?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  promo?: () => Maybe<Promo>;
  bestFor?: Maybe<Scalars['String']>;
  isCafII?: Maybe<Scalars['Boolean']>;
  displayPromoBanner?: Maybe<Scalars['Boolean']>;
  totalDiscount?: () => Maybe<Promo>;
  itemizedDiscounts?: () => Maybe<ItemDiscount>;
  offerId?: Maybe<Scalars['String']>;
  digitalServices?: () => Maybe<Array<DigitalService>>;
};

export type ProductCandidateGraphEditInput = {
  rmProdCandidateEdit?: () => Maybe<RmProdCandidateEditInput>;
  editType?: ProductCandidateGraphEditType;
  addRelnEdit?: () => Maybe<AddRelnEditInput>;
  id?: Scalars['ID'];
  rmRelnEdit?: () => Maybe<RmRelnEditInput>;
  replaceProdCandidateEdit?: () => Maybe<ReplaceProdCandidateEditInput>;
};

export type ProductCandidateGraphEditR0 = {
  id?: Scalars['String'];
  addRelnEdit?: () => Maybe<AddRelnEditR0>;
  editType?: Maybe<Scalars['String']>;
};

export enum ProductCandidateGraphEditType {
  AddReln = 'AddReln',
  ReplaceProdCandidateEdit = 'ReplaceProdCandidateEdit',
  RmProdCandidate = 'RmProdCandidate',
  RmReln = 'RmReln',
}

export enum ProductIdType {
  ProductCandidateId = 'ProductCandidateId',
  ProductInstanceId = 'ProductInstanceId',
}

export type ProductKind = {
  __typename?: string;
  productKindId?: Scalars['ID'];
  productKind?: Scalars['String'];
};

export type ProductMarketingCopy = {
  __typename?: string;
  translations?: () => Maybe<Array<ProductMarketingTranslations>>;
  uiBehaviors?: () => Maybe<Array<CartUiBehaviors>>;
};

export type ProductMarketingTranslations = {
  __typename?: string;
  name?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  characteristics?: () => Maybe<Array<CartCharacteristics>>;
};

export type ProductPromo = {
  __typename?: string;
  price?: Scalars['Float'];
  duration?: Scalars['Int'];
  unit?: Maybe<Scalars['String']>;
};

export type ProductType = {
  __typename?: string;
  id?: Scalars['ID'];
  name?: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  kind?: Scalars['String'];
  characteristics?: () => Array<Characteristic>;
  marketingCopy?: () => Maybe<Array<CartMarketingCopy>>;
  packageTypes?: () => Array<OfmPackageTypes>;
  prices?: () => Maybe<Array<Price>>;
  isRoot?: Scalars['Boolean'];
  products?: () => Maybe<Array<ProductType>>;
  uiBehaviors?: () => Maybe<Array<BehaviorClass>>;
};

export type ProductTypeR0 = {
  __typename?: string;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  serviceTypes?: () => Maybe<Array<Maybe<ServiceType>>>;
  packageTypes?: () => Maybe<Array<Maybe<PackageType>>>;
  prices?: () => Maybe<Array<Maybe<Price>>>;
  kind?: Maybe<Scalars['String']>;
  marketingCopy?: () => Maybe<Array<Maybe<MarketingCopy>>>;
  characteristics?: () => Maybe<Array<Maybe<AttributeR0>>>;
  discounts?: () => Maybe<Array<Maybe<DiscountType>>>;
};

export type ProductTypesR0 = {
  __typename?: string;
  productTypes?: () => Array<Maybe<ProductTypeR0>>;
  message?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};

export type Promo = {
  __typename?: string;
  price?: Scalars['Float'];
  duration?: Scalars['Int'];
};

export type Query = {
  __typename?: string;
  authAndVoidCardPaymentVerification?: () => Maybe<VppAuthVoidResponse>;
  createVPPTransaction?: () => Maybe<VppTransactionPayload>;
  /** @deprecated Use getFeatureFlags instead */
  featureFlags?: () => Maybe<FeatureFlags>;
  getAddOns?: () => Array<Maybe<AddOn>>;
  getAddressDetails?: () => AddressDetailsPayload;
  getAddressSuggestions?: () => AddressSuggestionsPayload;
  getAvailableProducts?: () => Maybe<Array<Maybe<Product>>>;
  getAvailableWorkOrderSchedules?: () => Maybe<GetAvailableWorkOrderSchedulesPayload>;
  getCart?: () => Maybe<ShoppingCart>;
  getClosingOffers?: () => Maybe<Array<Maybe<Product>>>;
  getCustomer?: () => GetCustomerPayload;
  getFeatureFlags?: () => Maybe<FeatureFlags>;
  getFilteredAddresses?: () => FilteredAddressesPayload;
  getFulfillmentAgreements?: () => Maybe<GetFulfillmentAgreementsPayload>;
  getInstallationDates?: () => Maybe<AvailableAppointmentsPayloadR0>;
  getInstallationFee?: () => Maybe<InstallationFeeR0>;
  getOrderState?: () => Maybe<Order>;
  getPartnerAccount?: () => Maybe<PartnerAccountResponse>;
  getPaymentOnFile?: () => Maybe<GetPaymentOnFilePayload>;
  getTaxCodes?: () => Array<Maybe<TaxCode>>;
  getWorkOrder?: () => GetWorkOrderPayload;
  logAemErrors?: Maybe<Scalars['Boolean']>;
  logResetSession?: Maybe<Scalars['Boolean']>;
  logUrl?: Maybe<Scalars['Boolean']>;
  logUrlParams?: Maybe<Scalars['Boolean']>;
  performCreditCheck?: () => PerformCreditCheckResponse;
  retrieveVPPPaymentTransaction?: () => Maybe<VppRetrievePaymentPayload>;
  scrubAddress?: () => ScrubAddressPayload;
  updateBillingInformationBV?: () => Maybe<VppTransactionPayload>;
  updateVPPTransaction?: () => Maybe<VppTransactionPayload>;
  verifyNLAD?: () => Maybe<NladBenefitsResponse>;
};

export type QueryAuthAndVoidCardPaymentVerificationArgs = {
  input?: () => Maybe<AuthAndVoidCardPaymentVerificationInput>;
};

export type QueryCreateVppTransactionArgs = {
  input?: () => UpdateVppTransactionInput;
};

export type QueryFeatureFlagsArgs = {
  input?: Maybe<Scalars['ID']>;
};

export type QueryGetAddOnsArgs = {
  input?: () => GetAddOnsInput;
};

export type QueryGetAddressDetailsArgs = {
  placeId?: Scalars['String'];
};

export type QueryGetAddressSuggestionsArgs = {
  input?: Scalars['String'];
};

export type QueryGetAvailableProductsArgs = {
  input?: () => Maybe<GetAvailableProductsInputR0>;
};

export type QueryGetAvailableWorkOrderSchedulesArgs = {
  input?: () => GetAvailableWorkOrderSchedulesInput;
  filters?: () => GetAvailableWorkOrderSchedulesFilters;
};

export type QueryGetCartArgs = {
  input?: Scalars['ID'];
};

export type QueryGetClosingOffersArgs = {
  input?: () => Maybe<GetClosingOffersInput>;
};

export type QueryGetCustomerArgs = {
  input?: () => ContactMethodFilterInput;
};

export type QueryGetFeatureFlagsArgs = {
  input?: () => Maybe<FeatureFlagsInput>;
};

export type QueryGetFilteredAddressesArgs = {
  input?: Scalars['String'];
};

export type QueryGetFulfillmentAgreementsArgs = {
  input?: () => GetFulfillmentAgreementsInput;
};

export type QueryGetInstallationDatesArgs = {
  input?: () => Maybe<GetInstallationDatesInputR0>;
};

export type QueryGetInstallationFeeArgs = {
  input?: () => Maybe<GetInstallationFeeR0>;
};

export type QueryGetOrderStateArgs = {
  input?: () => GetOrderStateInput;
};

export type QueryGetPartnerAccountArgs = {
  input?: () => Maybe<GetPartnerAccountInputR0>;
};

export type QueryGetPaymentOnFileArgs = {
  input?: () => GetPaymentOnFileInput;
};

export type QueryGetTaxCodesArgs = {
  input?: () => LocationInput;
};

export type QueryGetWorkOrderArgs = {
  input?: () => GetWorkOrderInput;
};

export type QueryLogAemErrorsArgs = {
  input?: () => Maybe<AemErrorParams>;
};

export type QueryLogResetSessionArgs = {
  input?: Maybe<Scalars['String']>;
};

export type QueryLogUrlArgs = {
  input?: Maybe<Scalars['String']>;
};

export type QueryLogUrlParamsArgs = {
  input?: () => Maybe<QueryParams>;
};

export type QueryPerformCreditCheckArgs = {
  input?: () => Maybe<PerformCreditCheckInput>;
};

export type QueryRetrieveVppPaymentTransactionArgs = {
  input?: () => Maybe<RetrieveVppPaymentTransactionInput>;
};

export type QueryScrubAddressArgs = {
  input?: () => ScrubAddressInput;
};

export type QueryUpdateBillingInformationBvArgs = {
  input?: () => Maybe<UpdateBillingFormationInput>;
};

export type QueryUpdateVppTransactionArgs = {
  input?: () => Maybe<UpdateVppTransactionInput>;
};

export type QueryVerifyNladArgs = {
  input?: () => Maybe<NladBenefitsInput>;
};

export type QueryParams = {
  dealerId?: Maybe<Scalars['String']>;
  partnerId?: Maybe<Scalars['String']>;
  affiliateId?: Maybe<Scalars['String']>;
  sessionId?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  municipality?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  plnId?: Maybe<Scalars['String']>;
  hideCta?: Maybe<Scalars['Boolean']>;
  offerId?: Maybe<Scalars['String']>;
};

export type RelationshipInstance = {
  __typename?: string;
  relnId?: Scalars['ID'];
};

export type RemoveCartItems = {
  cartId?: Scalars['ID'];
  productCandidateIds?: Array<Scalars['ID']>;
};

export type ReplaceProdCandidateEditInput = {
  newProductInstanceId?: Scalars['ID'];
  oldProductCandidateId?: Scalars['ID'];
};

export enum ResponseAttributeName {
  IsEligible = 'IS_ELIGIBLE',
  EquifaxTransactionId = 'EQUIFAX_TRANSACTION_ID',
  CreditTag = 'CREDIT_TAG',
}

export type RetrieveVppPaymentTransactionInput = {
  transactionId?: Scalars['String'];
};

export type RmProdCandidateEditInput = {
  productCandidateId?: Scalars['ID'];
};

export type RmRelnEditInput = {
  relnId?: Scalars['ID'];
};

export type SalesPartner = {
  __typename?: string;
  salesPartnerId?: Scalars['ID'];
  salesPartnerName?: Maybe<Scalars['String']>;
};

export type Schedule = {
  __typename?: string;
  from?: Scalars['LocalDateTime'];
  to?: Scalars['LocalDateTime'];
};

export type ScheduleInput = {
  from?: Scalars['LocalDateTime'];
  to?: Scalars['LocalDateTime'];
};

export type ScheduleR0 = {
  __typename?: string;
  from?: Scalars['String'];
  to?: Scalars['String'];
};

export type ScheduleWorkOrderInputR0 = {
  externalWorkOrderId?: Maybe<Scalars['String']>;
  schedule?: () => Maybe<WoScheduleInputR0>;
  note?: () => Maybe<NoteInputR0>;
};

export type ScheduleWorkOrderPayloadR0 = {
  __typename?: string;
  externalWorkOrderId?: Maybe<Scalars['String']>;
  schedule?: () => WoScheduleR0;
  note?: () => Maybe<NoteR0>;
};

export type ScrubAddressInput = {
  address?: () => AddressInputR0;
};

export type ScrubAddressPayload = {
  __typename?: string;
  addressLines?: Array<Scalars['String']>;
  municipality?: Scalars['String'];
  region?: Scalars['String'];
  countryCode?: Scalars['String'];
  postalCode?: Scalars['String'];
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  invalidAddress?: Scalars['Boolean'];
  bannedPostalCode?: Scalars['Boolean'];
  processStatus?: Scalars['String'];
  recommendation?: Scalars['String'];
};

export type SendLeadToMarketoResponse = {
  __typename?: string;
  sendLeadToMarketo?: Maybe<Scalars['Boolean']>;
};

export type ServiceLocationR0 = {
  addressLines?: Array<Scalars['String']>;
  countryCode?: Scalars['String'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  regionOrState?: Maybe<Scalars['String']>;
  zipOrPostCode?: Maybe<Scalars['String']>;
};

export type ServiceType = {
  __typename?: string;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  characteristics?: () => Maybe<Array<Maybe<AttributeR0>>>;
};

export type ShoppingCart = {
  __typename?: string;
  id?: Scalars['ID'];
  status?: Scalars['String'];
  cartItems?: () => CartItems;
  prices?: () => ShoppingCartPrices;
  leaseFeeShortName?: Scalars['String'];
  bundledAddons?: () => Maybe<Array<AddOn>>;
};

export type ShoppingCartAddonsInput = {
  cartId?: Scalars['ID'];
  cartName?: Maybe<Scalars['String']>;
  addonProductIds?: Array<Scalars['String']>;
  postalCode?: Scalars['String'];
};

export type ShoppingCartPlanInput = {
  cartId?: Maybe<Scalars['ID']>;
  cartName?: Maybe<Scalars['String']>;
  planProductId?: Scalars['String'];
  location?: () => OfmLocation;
  salesAgreementId?: Scalars['String'];
  closingOfferProductId?: Maybe<Scalars['String']>;
};

export type ShoppingCartPrices = {
  __typename?: string;
  monthly?: () => ShoppingCartPricesMonthly;
  oneTime?: () => ShoppingCartPricesOneTime;
};

export type ShoppingCartPricesMonthly = {
  __typename?: string;
  totalWithPromos?: Maybe<Scalars['Float']>;
  totalWithoutPromos?: Maybe<Scalars['Float']>;
  lineItems?: () => Maybe<LineItemsMonthly>;
};

export type ShoppingCartPricesOneTime = {
  __typename?: string;
  totalWithPromos?: Maybe<Scalars['Float']>;
  totalWithoutPromos?: Maybe<Scalars['Float']>;
  lease?: Maybe<Scalars['Float']>;
  lineItems?: () => Maybe<LineItemsOneTime>;
  feesAndDiscounts?: () => Maybe<Array<FeesAndDiscounts>>;
};

export type SmbCustomer = {
  __typename?: string;
  isCustomer?: Scalars['Boolean'];
  existingContactMethod?: Maybe<ExistingContactMethod>;
  individualPartyId?: Scalars['String'];
  relnId?: Scalars['String'];
  organizationPartyId?: Scalars['String'];
};

export enum StateR0 {
  Canceled = 'Canceled',
  Canceling = 'Canceling',
  CancelingError = 'CancelingError',
  Closed = 'Closed',
  Initializing = 'Initializing',
  InitializingError = 'InitializingError',
  Open = 'Open',
  OpenError = 'OpenError',
  Processed = 'Processed',
  ProcessedError = 'ProcessedError',
  Rejected = 'Rejected',
  Scheduled = 'Scheduled',
}

export type StringSetAttributeInput = {
  qualifiedName?: Scalars['ID'];
  value?: Array<Scalars['String']>;
};

export type SubmitFullOrderAttributes = {
  dealerId?: Maybe<Scalars['ID']>;
  partnerId?: Maybe<Scalars['ID']>;
  affiliateId?: Maybe<Scalars['ID']>;
  sessionId?: Maybe<Scalars['ID']>;
  campaignId?: Maybe<Scalars['ID']>;
  adobeEcId?: Maybe<Scalars['ID']>;
  transactionType?: Maybe<Scalars['Boolean']>;
  transferConsent?: Maybe<Scalars['Boolean']>;
  tribalBenefit?: Maybe<Scalars['Boolean']>;
  responseCode?: Maybe<Scalars['String']>;
};

export type SubmitFullOrderCustomer = {
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  dob?: () => Maybe<DateOfBirth>;
  serviceLocation?: () => LocationInput;
  billingAddress?: () => AddressInputOm;
  shippingAddress?: () => AddressInputOm;
  taxId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type SubmitFullOrderInput = {
  cartId?: Scalars['ID'];
  orderId?: Scalars['ID'];
  billingAccountId?: Scalars['ID'];
  applicationId?: Maybe<Scalars['ID']>;
  partyId?: Maybe<Scalars['ID']>;
  customerRelationshipId?: Scalars['ID'];
  customer?: () => SubmitFullOrderCustomer;
  payment?: () => SubmitFullOrderPayment;
  attributes?: () => SubmitFullOrderAttributes;
  workOrderInformation?: () => Maybe<WorkOrderInformation>;
  salesAgreementId?: Scalars['String'];
  fulfillmentAgreementId?: Scalars['String'];
  billingAccountIdToDeactivateList?: () => Array<Maybe<Scalars['String']>>;
  transactionId?: Maybe<Scalars['String']>;
  isBusinessAccount?: Maybe<Scalars['Boolean']>;
};

export type SubmitFullOrderPayload = {
  __typename?: string;
  orderId?: Scalars['ID'];
  externalWorkOrderId?: Scalars['ID'];
};

export type SubmitFullOrderPayment = {
  transactionId?: Scalars['ID'];
  paymentType?: Scalars['String'];
  userAgent?: Scalars['String'];
  paymentOnFileId?: Scalars['String'];
};

export type SubmitOrderInput = {
  orderId?: Scalars['ID'];
  customerRelationshipId?: Scalars['ID'];
  salesAgreementId?: Scalars['ID'];
  fulfillmentAgreementId?: Scalars['ID'];
  orderLines?: () => Array<SubmitOrderLineItemInput>;
  attributes?: () => Maybe<Array<OrderAttributeInput>>;
};

export type SubmitOrderLineItemInput = {
  orderLineId?: Scalars['ID'];
  orderLineType?: Scalars['String'];
  shoppingCartId?: Scalars['ID'];
  taxJurisdiction?: () => Maybe<TaxJurisdiction>;
  billingAccountId?: Scalars['ID'];
  serviceLocation?: () => Maybe<LocationInput>;
  serviceLocationId?: Maybe<Scalars['ID']>;
  workOrderInformation?: () => Maybe<WorkOrderInformation>;
  contractId?: Maybe<Scalars['ID']>;
};

export type Success = {
  __typename?: string;
  success?: Scalars['Boolean'];
};

export type TaxCode = {
  __typename?: string;
  taxCodeType?: Maybe<Scalars['String']>;
  taxCodeValue?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
};

export type TaxCodeInput = {
  label?: Maybe<Scalars['String']>;
  taxCodeType?: Maybe<Scalars['String']>;
  taxCodeValue?: Maybe<Scalars['String']>;
};

export type TaxJurisdiction = {
  taxCodeType?: Scalars['String'];
  taxCodeValue?: Scalars['String'];
};

export type ThinProductInput = {
  id?: Scalars['String'];
  kind?: Scalars['String'];
  existing?: Scalars['Boolean'];
  characteristics?: () => Array<CharacteristicInput>;
  name?: Maybe<Scalars['String']>;
};

export type TotalDiscount = {
  __typename?: string;
  name?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  amount?: () => Maybe<Array<Maybe<Amount>>>;
};

export type TransactionPayload = {
  __typename?: string;
  relnId?: Maybe<Scalars['String']>;
  spbBillingAccountId?: Maybe<Scalars['String']>;
  oneTimeTotal?: Maybe<Scalars['Int']>;
  userAgent?: Maybe<Scalars['String']>;
  paymentOnFileId?: Scalars['String'];
  transactionId?: Maybe<Scalars['String']>;
};

export type UpdateBillingAccountInput = {
  accountNumber?: Scalars['String'];
  taxCode?: () => TaxCodeInput;
  paymentReference?: Maybe<Scalars['String']>;
  isRegulated?: Maybe<Scalars['Boolean']>;
  isExemptFromLateFee?: Maybe<Scalars['Boolean']>;
  isExemptFromTaxation?: Maybe<Scalars['Boolean']>;
};

export type UpdateBillingFormationInput = {
  relnId?: Scalars['String'];
  billingAddress?: () => AddressCscr0;
  taxCode?: () => TaxCodeInput;
  transactionId?: Scalars['String'];
  txnAmount?: Scalars['Int'];
};

export type UpdateContactMethodInput = {
  partyId?: Scalars['ID'];
  billingAddressId?: Maybe<Scalars['ID']>;
  phone?: () => AddPhoneInput;
  email?: () => AddEmailInput;
  address?: () => AddAddressInput;
};

export type UpdateIndividualInput = {
  partyId?: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  serviceAddress?: () => Maybe<AddressInput>;
  billingAddress?: () => Maybe<AddressInput>;
  shippingAddress?: () => Maybe<AddressInput>;
  taxId?: Maybe<Scalars['String']>;
};

export type UpdateIndividualResponse = {
  __typename?: string;
  updateCreditCheck?: Scalars['Boolean'];
};

export type UpdateMarketingPreferences = {
  relnId?: Maybe<Scalars['String']>;
  hasCustomerOptedIn?: Maybe<Scalars['Boolean']>;
};

export type UpdateMarketingPreferencesResponse = {
  __typename?: string;
  updateMarketingPreferences?: Maybe<Scalars['Boolean']>;
};

export type UpdateOrganizationInput = {
  organizationName?: Scalars['String'];
  organizationPartyId?: Scalars['String'];
  individualPartyId?: Scalars['String'];
  firstName?: Scalars['String'];
  lastName?: Scalars['String'];
  email?: Scalars['String'];
  phoneNumber?: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  serviceAddress?: () => AddressInput;
  billingAddress?: () => Maybe<AddressInput>;
  shippingAddress?: () => Maybe<AddressInput>;
  taxId?: Maybe<Scalars['String']>;
  isTaxIdSsn?: Maybe<Scalars['Boolean']>;
  isTaxExempt?: Maybe<Scalars['Boolean']>;
};

export type UpdateOrganizationResponse = {
  __typename?: string;
  success?: Scalars['Boolean'];
};

export type UpdateRelationshipIdInput = {
  relnId?: Scalars['String'];
  accountNumber?: Scalars['String'];
  accountNumbersToRemove?: () => Maybe<Array<Maybe<Scalars['String']>>>;
};

export type UpdateVppTransactionInput = {
  customerRef?: Maybe<Scalars['String']>;
  spbBillingAccountId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  txnAmount?: Maybe<Scalars['Int']>;
  userAgent?: Maybe<Scalars['String']>;
  blockedPaymentMethods?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  email?: Maybe<Scalars['String']>;
  billingAddress?: () => Maybe<AddressInput>;
};

export type VppAuthVoidResponse = {
  __typename?: string;
  transactionId?: Maybe<Scalars['String']>;
  authVoidSuccessful?: Maybe<Scalars['Boolean']>;
};

export type VppRetrievePaymentPayload = {
  __typename?: string;
  userAgent?: Maybe<Scalars['String']>;
  txnType?: Maybe<Scalars['String']>;
  txnStatus?: Maybe<Scalars['String']>;
  txnId?: Maybe<Scalars['String']>;
  txnAmount?: Maybe<Scalars['Int']>;
  systemName?: Maybe<Scalars['String']>;
  secondaryTxns?: () => Maybe<Array<Maybe<Scalars['String']>>>;
  returnURL?: Maybe<Scalars['String']>;
  rescueStatus?: Maybe<Scalars['String']>;
  recurringPayment?: Maybe<Scalars['Boolean']>;
  paymentSource?: Maybe<Scalars['String']>;
  paymentProduct?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<Scalars['String']>;
  paymentInfoSource?: Maybe<Scalars['String']>;
  parentTxn?: Maybe<Scalars['String']>;
  LastModifiedDate?: Maybe<Scalars['Date']>;
  languageCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  customerRef?: Maybe<Scalars['String']>;
  currencyIsoCode?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['String']>;
  correlationId?: Maybe<Scalars['String']>;
  cancelURL?: Maybe<Scalars['String']>;
  billingAddress?: () => Maybe<NullableAddress>;
  additionalDetails?: Maybe<Scalars['String']>;
};

export type VppSubmitSalePayload = {
  __typename?: string;
  success?: Maybe<Scalars['Boolean']>;
};

export type VppTransactionPayload = {
  __typename?: string;
  transactionId?: Maybe<Scalars['String']>;
};

export type WoScheduleInputR0 = {
  from?: Scalars['String'];
  to?: Scalars['String'];
};

export type WoScheduleR0 = {
  __typename?: string;
  from?: Scalars['String'];
  to?: Scalars['String'];
};

export type WorkOrderInformation = {
  appointment?: () => Appointment;
  notes?: Maybe<Scalars['String']>;
  onSiteContactInformation?: () => Maybe<OnSiteContactInformation>;
};

export type WorkOrderInput = {
  externalWorkOrderId?: Maybe<Scalars['String']>;
  schedule?: () => Maybe<WoScheduleInputR0>;
  note?: () => Maybe<NoteInputR0>;
  skipWorkOrder?: Maybe<Scalars['Boolean']>;
};

export type WorkOrderType = {
  __typename?: string;
  workOrderTypeId?: Scalars['ID'];
  workOrderType?: Scalars['String'];
};

export type AddBillingAccountResponse = {
  __typename?: string;
  billingAcount?: () => Maybe<BillingAccount>;
};

export type LineItemsMonthly = {
  __typename?: string;
  promo?: Scalars['Float'];
  lease?: Maybe<Scalars['Float']>;
};

export type LineItemsOneTime = {
  __typename?: string;
  promo?: Maybe<Scalars['Float']>;
};
