import i18n from '@buy-viasat/utils/src/lib/config/i18n';
import { Country } from '@buy-viasat/types/build/bv';
import { houseNumberRegex, streetRegex, zipCodeRegex } from '@buy-viasat/utils';

type ValidationPayload = { valid: boolean; hintText: string };

const createFieldRequiredPayload = (hintText: string): ValidationPayload => ({ valid: false, hintText });

const validateWithRegex = (value: string, regex: RegExp): boolean => regex.test(value);

const validateRequired = (value: string | undefined | null, hintText: string | null = null): ValidationPayload =>
  value?.trim()
    ? { valid: true, hintText: '' }
    : hintText
    ? createFieldRequiredPayload(hintText)
    : { valid: false, hintText: i18n.t('components:thisFieldIsRequired') };

export const validateHouseNumber = (value: string | undefined | null): ValidationPayload => {
  return value
    ? {
        valid: validateWithRegex(value, houseNumberRegex),
        hintText: i18n.t('components:accountDetails.billingAddress.zipcodeIsInvalid'),
      }
    : createFieldRequiredPayload(i18n.t('components:accountDetails.billingAddress.zipCodeRequired'));
};

export const validateMunicipality = (value: string | undefined | null) =>
  validateRequired(value, i18n.t('components:accountDetails.billingAddress.cityRequired'));

export const validateRegion = (value: string | undefined | null) =>
  validateRequired(value, i18n.t('components:accountDetails.billingAddress.stateRequired'));

export const validateStreetAddress = (value: string | undefined | null) =>
  value
    ? {
        valid: validateWithRegex(value, streetRegex),
        hintText: i18n.t('components:accountDetails.billingAddress.streetAddressInvalid'),
      }
    : createFieldRequiredPayload(i18n.t('components:accountDetails.billingAddress.streetAddressRequired'));

export const validateZip = (value: string | undefined | null, zipRegex: RegExp, length?: number): ValidationPayload => {
  if (zipRegex === zipCodeRegex[Country.BR] && length === 5) {
    zipRegex = zipCodeRegex[Country.US];
  }

  return value
    ? {
        valid: validateWithRegex(value, zipRegex),
        hintText: i18n.t('components:accountDetails.billingAddress.zipcodeIsInvalid'),
      }
    : createFieldRequiredPayload(i18n.t('components:accountDetails.billingAddress.zipCodeRequired'));
};
