import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { TrackingActions, TrackingCategory } from '@buy-viasat/redux/src/analytics';
import {
  appActions,
  CustomerType,
  Modals,
  selectCountry,
  selectCustomerType,
  selectFeatureFlags,
  selectPartyId,
  selectRelnId,
  selectSalesFlowDefinition,
} from '@buy-viasat/redux/src/app';
import {
  addressActions,
  AddressFieldStateType,
  AddressType,
  formatScrubbedAddressHousingNumber,
  hasAddressBeenChanged,
  selectBillingAddress,
  selectBillingAddressHouseNumber,
  selectBillingAddressValues,
  selectBillingEqualsServiceAddress,
  selectIsBillingAddressValid,
  selectIsShippingAddressValid,
  selectPreviousBillingAddressValues,
  selectPreviousShippingAddressValues,
  selectShippingAddress,
  selectShippingAddressValues,
  selectShippingEqualsServiceAddress,
} from '@buy-viasat/redux/src/address';
import { navActions, Routes } from '@buy-viasat/redux/src/navigator';
import {
  selectCustomerInformation,
  selectIsMarketingCheckboxChecked,
  selectPhoneCountryCode,
  selectPreviousCustomerInfo,
} from '@buy-viasat/redux/src/personal-information';
import { selectIsVermontCustomer, selectServiceAddressValues } from '@buy-viasat/redux/src/serviceability';
import {
  Country,
  CreateCustomerResponse,
  CreateIndividualInput,
  CreateOrganizationInput,
  Customer,
  CustomerInfoMarketing,
  FeatureFlags,
  ProcessStatus,
  SmbCustomer,
  UpdateOrganizationInput,
} from '@buy-viasat/types/build/bv';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, CallEffect, put, PutEffect, select, SelectEffect, takeLatest } from 'redux-saga/effects';
import { TrackingLabel } from 'shared/containers/Analytics/types';
import { benchmark } from 'shared/containers/App/saga';
import sendLeadToMarketoAsync from 'shared/providers/requests/customer/sendLeadToMarketo';
import scrubAddressAsync from 'shared/providers/requests/fulfillment/scrubAddress';
import createIndividualAsync, { CreateIndividualResponse } from '../../providers/requests/customer/createIndividual';
import updateIndividualAsync, { UpdateIndividualPayload } from '../../providers/requests/customer/updateIndividual';
import { ErrorPayload, PartyId } from '../../types';
import { areAddressesEqual, isCustomerInfoEqual } from '../../utils';
import clientLogger from '../../utils/clientLogger';
import { creditVerificationActions } from '../CreditVerification/slice';
import { selectPersonalInformationValidation } from './selectors';
import { personalInformationActions } from './slice';
import { CreateCustomerPartyAndRelnId, CustomerInfo, NavigationGeneratorAction } from './types';
import updateMarketingPreferencesAsync from 'shared/providers/requests/customer/updateMarketingPreferences';
import { selectTaxId } from '@buy-viasat/redux/src/credit-verification';
import {
  businessInformationActions,
  selectAccountHolderInformation,
  selectBusinessInformationName,
  selectBusinessInformationSSN,
  selectBusinessInformationTaxID,
  selectIsTaxExempt,
  selectOrganizationPartyId,
  selectShouldUseTaxId,
} from '@buy-viasat/redux/src/business-information';
import createOrganizationAsync, {
  CreateOrganizationResponse,
} from 'shared/providers/requests/customer/createOrganization';
import updateOrganizationAsync, {
  UpdateOrganizationPayload,
} from 'shared/providers/requests/customer/updateOrganization';
import { paymentInformationActions } from '@buy-viasat/redux/src/payment-information';
import createCustomer, { CreateCustomerResult } from 'shared/providers/requests/customer/createCustomer';
import { selectIsUpsertPlanLoading } from '@buy-viasat/redux/src/plan';
import { triggerSetCallingSaga } from 'shared/containers/App/counterSaga';

const VT = 'VT';

export function* navigationGenerator(
  action: PayloadAction<NavigationGeneratorAction>,
): Generator<PutEffect | CallEffect | SelectEffect, void, void> {
  const billingEqualsService: any = yield select(selectBillingEqualsServiceAddress);
  const isUpsertPlanLoading: any = yield select(selectIsUpsertPlanLoading);

  if (
    action.payload.billingAddress?.region === VT ||
    (action.payload.serviceAddress.region === VT && billingEqualsService)
  ) {
    yield put(creditVerificationActions.checkCustomerCredit());
  } else {
    if (!isUpsertPlanLoading) {
      yield put(navActions.next());
    } else {
      yield put(personalInformationActions.setIsPersonalInformationRequestComplete(true));
    }
  }
}

export function* createCustomerSaga(
  action: PayloadAction<CreateIndividualInput>,
): Generator<
  CallEffect | PutEffect | SelectEffect,
  CreateCustomerPartyAndRelnId,
  (CreateIndividualResponse | CreateCustomerResult) & FeatureFlags
> {
  try {
    const { enableIraNewEndpoint } = yield select(selectFeatureFlags);
    const serviceAddress: any = yield select(selectServiceAddressValues);
    const billingAddress: any = yield select(selectBillingAddressValues);
    const shippingAddress: any = yield select(selectShippingAddressValues);
    yield put(personalInformationActions.setIsPersonalInformationLoading(true));
    let partyId: string;
    let relnId: string;

    if (enableIraNewEndpoint) {
      const { data } = yield call(createCustomer, { individual: action.payload });
      if (!data) throw new Error('No data returned from IRA - createCustomer');
      const response = data as { createCustomer: CreateCustomerResponse };
      yield put(
        appActions.setPartyAndRelnId({
          partyId: response.createCustomer.individualPartyId,
          relnId: response.createCustomer.relnId,
        }),
      );
      partyId = response.createCustomer.individualPartyId;
      relnId = response.createCustomer.relnId;
    } else {
      const { data } = yield call(createIndividualAsync, action.payload);
      if (!data) throw new Error('No data returned from IRA - createIndividual');
      const response = data as { createIndividual: Customer };
      yield put(
        appActions.setPartyAndRelnId({
          partyId: response.createIndividual.partyId,
          relnId: response.createIndividual.relnId,
        }),
      );
      partyId = response.createIndividual.partyId;
      relnId = response.createIndividual.relnId;
    }

    yield put(personalInformationActions.setMarketPreferences());
    yield put(personalInformationActions.navigationGenerator({ billingAddress, serviceAddress, shippingAddress }));
    return { partyId, relnId };
  } catch (err: any) {
    yield put(personalInformationActions.onSagaError({ callingSaga: 'createCustomerSaga', err, payload: null }));
    return { partyId: '', relnId: '' };
  }
}

export function* createOrganizationSaga(
  action: PayloadAction<CreateOrganizationInput>,
): Generator<
  CallEffect | PutEffect | SelectEffect,
  CreateCustomerPartyAndRelnId,
  (CreateOrganizationResponse | CreateCustomerResult) & FeatureFlags
> {
  try {
    const { enableIraNewEndpoint } = yield select(selectFeatureFlags);
    const serviceAddress: any = yield select(selectServiceAddressValues);
    const billingAddress: any = yield select(selectBillingAddressValues);
    const shippingAddress: any = yield select(selectShippingAddressValues);
    yield put(personalInformationActions.setIsPersonalInformationLoading(true));
    let partyId: string;
    let relnId: string;

    if (enableIraNewEndpoint) {
      const { data } = yield call(createCustomer, { organization: action.payload });
      if (!data) throw new Error('No data returned from IRA - createCustomer');
      const response = data as { createCustomer: CreateCustomerResponse };
      yield put(
        appActions.setPartyAndRelnId({
          partyId: response.createCustomer.individualPartyId,
          relnId: response.createCustomer.relnId,
        }),
      );
      partyId = response.createCustomer.individualPartyId;
      relnId = response.createCustomer.relnId;
      yield put(appActions.setOrganizationPartyId(response.createCustomer.organizationPartyId));
      yield put(businessInformationActions.setOrganizationPartyId(response.createCustomer.organizationPartyId));
    } else {
      const { data } = yield call(createOrganizationAsync, action.payload);
      if (!data) throw new Error('No data returned from IRA - createOrganization');
      const response = data as { createOrganization: SmbCustomer };
      yield put(
        appActions.setPartyAndRelnId({
          partyId: response.createOrganization.individualPartyId,
          relnId: response.createOrganization.relnId,
        }),
      );
      partyId = response.createOrganization.individualPartyId;
      relnId = response.createOrganization.relnId;
      yield put(appActions.setOrganizationPartyId(response.createOrganization.organizationPartyId));
      yield put(businessInformationActions.setOrganizationPartyId(response.createOrganization.organizationPartyId));
    }

    yield put(personalInformationActions.setMarketPreferences());
    yield put(personalInformationActions.navigationGenerator({ billingAddress, serviceAddress, shippingAddress }));
    return { partyId, relnId };
  } catch (err: any) {
    yield put(personalInformationActions.onSagaError({ callingSaga: 'createOrganizationSaga', err, payload: null }));
    return { partyId: '', relnId: '' };
  }
}

export function* updateCustomerSaga(
  action: PayloadAction<CreateIndividualInput & PartyId>,
): Generator<CallEffect | PutEffect | SelectEffect, void, UpdateIndividualPayload> {
  try {
    const serviceAddress: any = yield select(selectServiceAddressValues);
    const billingAddress: any = yield select(selectBillingAddressValues);
    const shippingAddress: any = yield select(selectShippingAddressValues);
    yield put(personalInformationActions.setIsPersonalInformationLoading(true));
    const { data } = yield call(updateIndividualAsync, action.payload);
    yield put(creditVerificationActions.updateCreditVerification(data?.updateIndividual.updateCreditCheck ?? false));
    yield put(personalInformationActions.setMarketPreferences());
    yield put(personalInformationActions.navigationGenerator({ billingAddress, serviceAddress, shippingAddress }));
  } catch (err: any) {
    yield put(personalInformationActions.onSagaError({ callingSaga: 'updateCustomerSaga', err, payload: null }));
  }
}

export function* updateOrganizationSaga(
  action: PayloadAction<UpdateOrganizationInput>,
): Generator<CallEffect | PutEffect | SelectEffect, void, UpdateOrganizationPayload> {
  try {
    const serviceAddress: any = yield select(selectServiceAddressValues);
    const billingAddress: any = yield select(selectBillingAddressValues);
    const shippingAddress: any = yield select(selectShippingAddressValues);
    yield put(personalInformationActions.setIsPersonalInformationLoading(true));
    yield call(updateOrganizationAsync, action.payload);
    yield put(personalInformationActions.setMarketPreferences());
    yield put(personalInformationActions.navigationGenerator({ billingAddress, serviceAddress, shippingAddress }));
  } catch (err: any) {
    yield put(personalInformationActions.onSagaError({ callingSaga: 'updateOrganizationSaga', err, payload: null }));
  }
}

export function* createOrUpdateCustomerSaga(): Generator<
  PutEffect | SelectEffect | CallEffect,
  void,
  string & AddressType & boolean
> {
  yield put(appActions.setIsModalVisible(false));

  const phoneCountryCode: CountryCode = yield select(selectPhoneCountryCode);
  const partyId: string = yield select(selectPartyId);
  const serviceAddress: AddressType = yield select(selectServiceAddressValues);
  const billingAddress: AddressType = yield select(selectBillingAddressValues);
  const shippingAddress: AddressType = yield select(selectShippingAddressValues);
  const customerType: CustomerType = yield select(selectCustomerType);
  const organizationName = yield select(selectBusinessInformationName);
  const businessTaxId = yield select(selectBusinessInformationTaxID);
  const businessSSN = yield select(selectBusinessInformationSSN);
  const shouldUseTaxId = yield select(selectShouldUseTaxId);
  const organizationPartyId = yield select(selectOrganizationPartyId);
  const isOrganization = customerType === CustomerType.BUSINESS;
  const isVermontCustomer: boolean = yield select(selectIsVermontCustomer);
  const isTaxExempt: boolean = yield select(selectIsTaxExempt);

  const customerInfo: CustomerInfo = !isOrganization
    ? yield select(selectCustomerInformation)
    : yield select(selectAccountHolderInformation);
  const { shouldSendTaxIdOnCreateOrUpdateIndividual, shouldRunGetTaxCodesAfterPersonalInfoPage } = yield select(
    selectSalesFlowDefinition,
  );
  const taxId: any = yield select(selectTaxId);

  if (customerInfo.phoneNumber) {
    const parsedPhoneNumber = parsePhoneNumberFromString(customerInfo.phoneNumber, phoneCountryCode);
    if (parsedPhoneNumber) {
      customerInfo.phoneNumber = parsedPhoneNumber.format('E.164');
    }
  }

  if (customerInfo.mobileNumber) {
    const parsedMobileNumber = parsePhoneNumberFromString(customerInfo.mobileNumber, phoneCountryCode);
    if (parsedMobileNumber) {
      customerInfo.mobileNumber = parsedMobileNumber.format('E.164');
    }
  }

  const customerAddresses = {
    serviceAddress: serviceAddress,
    billingAddress: areAddressesEqual(billingAddress, serviceAddress) ? undefined : billingAddress,
    shippingAddress: areAddressesEqual(shippingAddress, serviceAddress) ? undefined : shippingAddress,
  };

  let createCustomerResponse: CreateCustomerPartyAndRelnId;
  if (partyId)
    isOrganization
      ? yield put(
          personalInformationActions.updateOrganization({
            individualPartyId: partyId,
            organizationPartyId: organizationPartyId,
            organizationName: organizationName,
            ...customerInfo,
            ...customerAddresses,
            taxId: shouldSendTaxIdOnCreateOrUpdateIndividual ? (shouldUseTaxId ? businessTaxId : businessSSN) : '',
            isTaxIdSsn: !shouldUseTaxId,
            isTaxExempt,
          }),
        )
      : yield put(
          personalInformationActions.updateCustomer({
            partyId,
            ...customerInfo,
            ...customerAddresses,
            taxId: shouldSendTaxIdOnCreateOrUpdateIndividual ? taxId.value : '',
          }),
        );
  else {
    if (isOrganization) {
      createCustomerResponse = yield call(createOrganizationSaga, {
        payload: {
          ...customerInfo,
          ...customerAddresses,
          organizationName: organizationName,
          taxId: shouldSendTaxIdOnCreateOrUpdateIndividual ? (shouldUseTaxId ? businessTaxId : businessSSN) : '',
          isTaxIdSsn: !shouldUseTaxId,
          isTaxExempt,
        },
        type: personalInformationActions.createOrganization.type,
      });
    } else {
      createCustomerResponse = yield call(createCustomerSaga, {
        payload: {
          ...customerInfo,
          ...customerAddresses,
          taxId: shouldSendTaxIdOnCreateOrUpdateIndividual ? taxId.value : '',
        },
        type: personalInformationActions.createCustomer.type,
      });
    }
    yield put(appActions.setPartyId(createCustomerResponse.partyId));
    yield put(appActions.setRelnId(createCustomerResponse.relnId));

    if (shouldRunGetTaxCodesAfterPersonalInfoPage || isVermontCustomer) {
      yield put(paymentInformationActions.getTaxCodes());
    }
  }
}

function* onEditPostalCodeClickSaga(): Generator {
  yield put(
    appActions.analyticsEvent({
      category: TrackingCategory.NAV,
      action: TrackingActions.CLICKED,
      params: { label: TrackingLabel.UPDATE_ZIPCODE },
    }),
  );
  yield put(appActions.setIsCartVisible(false));
  yield put(personalInformationActions.setIsPersonalInformationLoading(false));
  yield put(navActions.routeUserTo(Routes.SERVICEABILITY));
}

function* onErrorSaga(action: PayloadAction<ErrorPayload>): Generator {
  clientLogger(action.payload.callingSaga, action.payload.err);
  yield put(
    appActions.analyticsEvent({
      category: TrackingCategory.PERSONAL_INFORMATION,
      action: TrackingActions.ERROR,
      params: undefined,
    }),
  );
  yield put(personalInformationActions.setIsPersonalInformationLoading(false));
  yield put(appActions.setIsCheckoutButtonDisabled(false));
  yield put(appActions.setModalVisible(Modals.ERROR));
  yield call(triggerSetCallingSaga, action.payload.callingSaga);
}

export function* validateAndCreateOrUpdateCustomer() {
  const formValid: boolean = yield select(selectPersonalInformationValidation);
  if (formValid) {
    const previousCustomerInfo: CustomerInfoMarketing = yield select(selectPreviousCustomerInfo);
    const customerInfo: CustomerInfo = yield select(selectCustomerInformation);
    const isMarketingCheckboxChecked: boolean = yield select(selectIsMarketingCheckboxChecked);
    const { enableMarketingConsent, displayRemarketingCheckbox } = yield select(selectFeatureFlags);
    const serviceAddress: AddressFieldStateType = yield select(selectServiceAddressValues);
    const derivedAddress = `${serviceAddress.addressLines}, ${serviceAddress.municipality}, ${serviceAddress.postalCode}, ${serviceAddress.region}, ${serviceAddress.countryCode}`;
    const billingAddress: AddressFieldStateType = yield select(selectBillingAddress);
    const shippingAddress: AddressFieldStateType = yield select(selectShippingAddress);

    const shouldSendLeadToMarketo =
      (displayRemarketingCheckbox && isMarketingCheckboxChecked && enableMarketingConsent) ||
      (!displayRemarketingCheckbox && enableMarketingConsent);

    if (shouldSendLeadToMarketo && !isCustomerInfoEqual(customerInfo, previousCustomerInfo, derivedAddress)) {
      yield put(
        personalInformationActions.setCustomerInfoToCompare({ ...customerInfo, serviceAddress: derivedAddress }),
      );
      yield call<any>(sendLeadToMarketoAsync, {
        ...customerInfo,
        serviceAddress: derivedAddress,
      });
    }

    yield put(addressActions.setPreviousBillingAddress(billingAddress));
    yield put(addressActions.setPreviousShippingAddress(shippingAddress));
    yield call(createOrUpdateCustomerSaga);
  } else {
    yield put(personalInformationActions.setIsPersonalInformationLoading(false));
    yield put(appActions.setIsCheckoutButtonDisabled(false));
  }
}

export function* setBillingAddressValid() {
  const country: Country = yield select(selectCountry);
  yield put(addressActions.setBillingAddressLinesValid());
  yield put(addressActions.setBillingZipcodeValid(country));
  yield put(addressActions.setBillingMunicipalityValid());
  yield put(addressActions.setBillingRegionValid());
}

export function* setShippingAddressValid() {
  const country: Country = yield select(selectCountry);
  yield put(addressActions.setShippingAddressLinesValid());
  yield put(addressActions.setShippingZipcodeValid(country));
  yield put(addressActions.setShippingMunicipalityValid());
  yield put(addressActions.setShippingRegionValid());
}

export function* scrubBillingAddress() {
  const address: AddressType = yield select(selectBillingAddressValues);
  const houseNumber: string = yield select(selectBillingAddressHouseNumber);
  const { hideScrubAddressModal, displayHouseNumber } = yield select(selectFeatureFlags);
  const prevBillingAddress: AddressType = yield select(selectPreviousBillingAddressValues);

  const billingAddressChanged = hasAddressBeenChanged(address, prevBillingAddress);
  if (!billingAddressChanged) {
    yield call(validateShippingAddress);
    return;
  }

  try {
    yield put(personalInformationActions.setIsPersonalInformationLoading(true));
    const {
      data: { scrubAddress },
    } = yield call<any>(scrubAddressAsync, {
      address,
    });
    const { addressLines, region, municipality, postalCode, countryCode } = scrubAddress;

    const scrubbedAddress = formatScrubbedAddressHousingNumber(
      { addressLines, region, municipality, postalCode, countryCode },
      displayHouseNumber && houseNumber,
    );

    yield put(
      addressActions.setScrubBillingAddress({
        address: scrubbedAddress,
        coordinates: { latitude: scrubAddress.latitude, longitude: scrubAddress.longitude },
      }),
    );

    yield put(addressActions.setBillingAddress(address));
    if (scrubAddress.invalidAddress) {
      yield put(appActions.setModalVisible(Modals.BILLING_ADDRESS_NOT_VERIFIED));
      yield put(personalInformationActions.setIsPersonalInformationLoading(false));
      yield put(appActions.setIsCheckoutButtonDisabled(false));
      yield put(addressActions.setBillingAddress(scrubbedAddress));
    } else if (scrubAddress.processStatus === ProcessStatus.C) {
      yield put(personalInformationActions.setIsPersonalInformationLoading(false));
      yield put(appActions.setModalVisible(Modals.CONFIRM_BILLING_ADDRESS));
    } else if (scrubAddress.processStatus === ProcessStatus.V && hideScrubAddressModal) {
      yield put(addressActions.setBillingAddress(scrubbedAddress));
      yield call(setBillingAddressValid);
      yield call(validateShippingAddress);
    } else {
      yield put(personalInformationActions.setIsPersonalInformationLoading(false));
      yield put(appActions.setModalVisible(Modals.CONFIRM_BILLING_ADDRESS));
    }
  } catch (err: any) {
    yield put(personalInformationActions.setIsPersonalInformationLoading(false));
    clientLogger('scrubBillingAddress: ', err);
    yield put(
      appActions.analyticsEvent({
        category: TrackingCategory.BILLING_ADDRESS_CHECK,
        action: TrackingActions.ERROR,
        params: undefined,
      }),
    );
    yield put(
      appActions.setErrorRetryAction({ type: personalInformationActions.scrubBillingAddress.type, payload: null }),
    );
    yield put(appActions.setModalVisible(Modals.ERROR));
  }
}

export function* scrubShippingAddress() {
  const address: AddressType = yield select(selectShippingAddressValues);
  const { hideScrubAddressModal } = yield select(selectFeatureFlags);
  const prevShippingAddress: AddressType = yield select(selectPreviousShippingAddressValues);

  const shippingAddressChanged = hasAddressBeenChanged(address, prevShippingAddress);
  if (!shippingAddressChanged) {
    yield call(validateAndCreateOrUpdateCustomer);
    return;
  }

  try {
    yield put(personalInformationActions.setIsPersonalInformationLoading(true));
    const {
      data: { scrubAddress },
    } = yield call<any>(scrubAddressAsync, {
      address,
    });
    const scrubbedAddress = {
      addressLines: [scrubAddress.addressLines[0], scrubAddress.addressLines[1] ?? ''],
      region: scrubAddress.region,
      municipality: scrubAddress.municipality,
      postalCode: scrubAddress.postalCode,
      countryCode: scrubAddress.countryCode,
    };
    yield put(
      addressActions.setScrubShippingAddress({
        address: scrubbedAddress,
        coordinates: { latitude: scrubAddress.latitude, longitude: scrubAddress.longitude },
      }),
    );
    yield put(addressActions.setShippingAddress(address));
    if (scrubAddress.invalidAddress) {
      yield put(appActions.setModalVisible(Modals.SHIPPING_ADDRESS_NOT_VERIFIED));
      yield put(personalInformationActions.setIsPersonalInformationLoading(false));
      yield put(appActions.setIsCheckoutButtonDisabled(false));
    } else if (scrubAddress.processStatus === ProcessStatus.C) {
      yield put(personalInformationActions.setIsPersonalInformationLoading(false));
      yield put(appActions.setModalVisible(Modals.CONFIRM_SHIPPING_ADDRESS));
    } else if (scrubAddress.processStatus === ProcessStatus.V && hideScrubAddressModal) {
      yield put(addressActions.setShippingAddress(scrubbedAddress));
      yield call(setShippingAddressValid);
      yield call(validateAndCreateOrUpdateCustomer);
    } else {
      yield put(personalInformationActions.setIsPersonalInformationLoading(false));
      yield put(appActions.setModalVisible(Modals.CONFIRM_SHIPPING_ADDRESS));
    }
  } catch (err: any) {
    yield put(personalInformationActions.setIsPersonalInformationLoading(false));
    clientLogger('scrubShippingAddress: ', err);
    yield put(
      appActions.analyticsEvent({
        category: TrackingCategory.SHIPPING_ADDRESS_CHECK,
        action: TrackingActions.ERROR,
        params: undefined,
      }),
    );
    yield put(
      appActions.setErrorRetryAction({ type: personalInformationActions.scrubShippingAddress.type, payload: null }),
    );
    yield put(appActions.setModalVisible(Modals.ERROR));
  }
}

export function* validateBillingAddress() {
  const isBillingEqualServiceAddress: boolean = yield select(selectBillingEqualsServiceAddress);

  if (isBillingEqualServiceAddress) {
    const serviceAddress: AddressType = yield select(selectServiceAddressValues);
    yield put(addressActions.setBillingAddress(serviceAddress));
    yield put(navActions.setupAppRoutes());
    yield call(setBillingAddressValid);
    yield call(validateShippingAddress);
  } else {
    const country: Country = yield select(selectCountry);
    yield put(navActions.setupAppRoutes());
    yield put(addressActions.validateBillingAddress(country));
    yield put(addressActions.validateShippingAddress(country));
    const isBillingAddressValid: boolean = yield select(selectIsBillingAddressValid);
    const isShippingAddressValid: boolean = yield select(selectIsShippingAddressValid);
    const isShippingEqualServiceAddress: boolean = yield select(selectShippingEqualsServiceAddress);
    if ((isBillingAddressValid && isShippingAddressValid) || (isBillingAddressValid && isShippingEqualServiceAddress)) {
      yield put(personalInformationActions.scrubBillingAddress());
    } else {
      yield put(personalInformationActions.setIsPersonalInformationLoading(false));
      yield put(appActions.setIsCheckoutButtonDisabled(false));
    }
  }
}

export function* validateShippingAddress() {
  const isShippingEqualServiceAddress: boolean = yield select(selectShippingEqualsServiceAddress);

  if (isShippingEqualServiceAddress) {
    const serviceAddress: AddressType = yield select(selectServiceAddressValues);
    yield put(addressActions.setShippingAddress(serviceAddress));
    yield put(navActions.setupAppRoutes());
    yield call(setShippingAddressValid);
    yield call(validateAndCreateOrUpdateCustomer);
  } else {
    const country: Country = yield select(selectCountry);
    yield put(navActions.setupAppRoutes());
    yield put(addressActions.validateShippingAddress(country));
    yield put(addressActions.validateBillingAddress(country));
    const isShippingAddressValid: boolean = yield select(selectIsShippingAddressValid);
    const isBillingAddressValid: boolean = yield select(selectIsBillingAddressValid);
    const isBillingEqualServiceAddress: boolean = yield select(selectBillingEqualsServiceAddress);
    if ((isShippingAddressValid && isBillingAddressValid) || (isShippingAddressValid && isBillingEqualServiceAddress)) {
      yield put(personalInformationActions.scrubShippingAddress());
    } else {
      yield put(personalInformationActions.setIsPersonalInformationLoading(false));
      yield put(appActions.setIsCheckoutButtonDisabled(false));
    }
  }
}

export function* updateMarketPreferencesSaga() {
  try {
    const isMarketingCheckboxChecked: boolean = yield select(selectIsMarketingCheckboxChecked);
    const { enableMarketingConsent, displayRemarketingCheckbox } = yield select(selectFeatureFlags);
    const shouldSendLeadToMarketo =
      (displayRemarketingCheckbox && isMarketingCheckboxChecked && enableMarketingConsent) ||
      (!displayRemarketingCheckbox && enableMarketingConsent);
    const relnId: string = yield select(selectRelnId);

    yield call<any>(updateMarketingPreferencesAsync, {
      relnId,
      hasCustomerOptedIn: displayRemarketingCheckbox ? isMarketingCheckboxChecked : true,
    });
  } catch (err: any) {
    yield put(
      personalInformationActions.onSagaError({ callingSaga: 'updateMarketPreferencesSaga', err, payload: null }),
    );
  }
}

export function* personalInformationSaga(): Generator {
  yield takeLatest(personalInformationActions.navigationGenerator.type, navigationGenerator);
  yield takeLatest(personalInformationActions.onEditPostalCodeClick.type, benchmark(onEditPostalCodeClickSaga));
  yield takeLatest(
    personalInformationActions.updateCustomer.type,
    benchmark<CreateIndividualInput & PartyId, CallEffect | PutEffect | SelectEffect, void, UpdateIndividualPayload>(
      updateCustomerSaga,
    ),
  );
  yield takeLatest(
    personalInformationActions.updateOrganization.type,
    benchmark<UpdateOrganizationInput, CallEffect | PutEffect | SelectEffect, void, UpdateOrganizationPayload>(
      updateOrganizationSaga,
    ),
  );
  yield takeLatest(
    personalInformationActions.createCustomer.type,
    benchmark<
      CreateIndividualInput,
      CallEffect | PutEffect | SelectEffect,
      CreateCustomerPartyAndRelnId,
      CreateIndividualResponse
    >(createCustomerSaga),
  );
  yield takeLatest(
    personalInformationActions.createOrganization.type,
    benchmark<
      CreateOrganizationInput,
      CallEffect | PutEffect | SelectEffect,
      CreateCustomerPartyAndRelnId,
      CreateOrganizationResponse
    >(createOrganizationSaga),
  );
  yield takeLatest(personalInformationActions.validateShippingAddress.type, validateShippingAddress);
  yield takeLatest(personalInformationActions.validateBillingAddress.type, validateBillingAddress);
  yield takeLatest(
    personalInformationActions.validateAndCreateOrUpdateCustomer.type,
    validateAndCreateOrUpdateCustomer,
  );
  yield takeLatest(personalInformationActions.scrubBillingAddress.type, benchmark(scrubBillingAddress));
  yield takeLatest(personalInformationActions.scrubShippingAddress.type, benchmark(scrubShippingAddress));
  yield takeLatest(personalInformationActions.createOrUpdateCustomer.type, createOrUpdateCustomerSaga);
  yield takeLatest(personalInformationActions.onSagaError, benchmark<ErrorPayload>(onErrorSaga));
  yield takeLatest(personalInformationActions.setMarketPreferences.type, updateMarketPreferencesSaga);
}
