import { Button, Col, Container, StandAloneLink, Row } from '@vst/beam';
import { FC, MouseEventHandler } from 'react';
import { LoadingSpinner } from '../LoadingSpinner';
import styles from './plansNavigator.module.scss';

export type NavigatorProps = {
  backButtonText?: string;
  skipButtonText?: string;
  confirmButtonText: string;
  onBackButtonClick?: MouseEventHandler<HTMLAnchorElement>;
  onSkipButtonClick?: MouseEventHandler<HTMLButtonElement>;
  onConfirmButtonClick?: MouseEventHandler<HTMLButtonElement>;
  backButtonHref?: string;
  disabled?: boolean;
  loading?: boolean;
  loadingText?: string;
  buttonSize?: 'small' | 'medium' | 'large';
  noTopPadding?: boolean;
};

export const Navigator: FC<NavigatorProps> = ({
  backButtonText,
  skipButtonText,
  confirmButtonText,
  onBackButtonClick,
  onSkipButtonClick,
  onConfirmButtonClick,
  backButtonHref,
  disabled,
  loading,
  loadingText,
  buttonSize,
  noTopPadding,
}) => {
  const onBackButtonClickWrapper = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (onBackButtonClick) {
      e.preventDefault();
      e.stopPropagation();
      onBackButtonClick(e);
    }
  };
  return (
    <Container data-testid="plansNavigatorContainer">
      <Row className={noTopPadding ? styles['mainContainerNoPadding'] : styles['mainContainer']}>
        <Col className={styles['backButtonContainer']}>
          {backButtonText && (
            <StandAloneLink
              variant="primary"
              linkSize="medium"
              onClick={onBackButtonClickWrapper}
              children={backButtonText}
              disabled={disabled}
              data-testid="navigatorBackLink"
              href={backButtonHref ?? ''}
            />
          )}
        </Col>
        <Col xs={12} md={3} className={styles['skipButtonContainer']}>
          {skipButtonText && (
            <Button
              variant="secondary"
              className={styles['skipButton']}
              fluid
              onClick={onSkipButtonClick}
              children={skipButtonText}
              buttonSize={buttonSize}
              disabled={disabled || loading}
              data-testid="navigatorSkipButton"
            />
          )}
        </Col>
        <Col xs={12} md={4} className={styles['confirmButtonContainer']} style={{ minWidth: 'max-content' }}>
          {confirmButtonText && (
            <Button
              variant="primary"
              fluid
              onClick={onConfirmButtonClick}
              buttonSize={buttonSize}
              disabled={disabled || loading}
              type="submit"
              data-testid="navigatorContinueButton"
            >
              {loading ? <LoadingSpinner text={loadingText} size="small" color="secondary" /> : confirmButtonText}
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};
