import { benchmark } from 'shared/containers/App/saga';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  selectDateOfBirth,
  selectDisplaySSNMandatoryModal,
  selectLastFourOfSSN,
  selectTaxId,
  selectUpdateCreditVerification,
} from '@buy-viasat/redux/src/credit-verification';
import { creditVerificationActions } from './slice';
import {
  appActions,
  CREDIT_CHECK_ELIGIBILITY_VALUE,
  CreditTag,
  Modals,
  selectFeatureFlags,
  selectPartyId,
  selectSalesAgreementId,
  selectUrlParams,
} from '@buy-viasat/redux/src/app';
import { cartActions, selectCartId, selectOnetimeDiscount } from '@buy-viasat/redux/src/cart';
import { selectPlanId, selectPlanOfferId } from '@buy-viasat/redux/src/plan';
import performCreditCheckAsync, {
  PerformCreditCheckPayload,
} from '../../providers/requests/customer/performCreditCheck';
import clientLogger from '../../utils/clientLogger';
import { AddressType, Coordinates } from '@buy-viasat/redux/src/address';
import { personalInformationActions } from '../PersonalInformation/slice';
import {
  selectCoordinates,
  selectIsVermontCustomer,
  selectServiceAddressValues,
} from '@buy-viasat/redux/src/serviceability';
import { navActions, Routes } from '@buy-viasat/redux/src/navigator';
import { DateOfBirth, FormFieldState } from '../Checkout/types';
import { selectSubsidyApplicationId } from '@buy-viasat/redux/src/personal-information';
import i18n from '@buy-viasat/utils/src/lib/config/i18n';
import { paymentInformationActions } from '@buy-viasat/redux/src/payment-information';

export function* checkCustomerCreditSaga() {
  const onetimeDiscount: number = yield select(selectOnetimeDiscount);
  const partyId: string = yield select(selectPartyId);
  const cartId: string = yield select(selectCartId);
  const planId: string = yield select(selectPlanId);
  const serviceAddress: AddressType = yield select(selectServiceAddressValues);
  const coordinates: Coordinates = yield select(selectCoordinates);
  const lastFourOfSSN: string = yield select(selectLastFourOfSSN);
  const taxId: string = yield select(selectTaxId);
  const isVermontCustomer: boolean = yield select(selectIsVermontCustomer);
  const updateCreditVerification: boolean = yield select(selectUpdateCreditVerification);
  const { dealerId, partnerId } = yield select(selectUrlParams);
  const dateOfBirth: DateOfBirth<string, string, string> = yield select(selectDateOfBirth);
  const { isSSNMandatory } = yield select(selectFeatureFlags);
  const displaySSNMandatoryModal: boolean = yield select(selectDisplaySSNMandatoryModal);
  const subsidyApplicationId: FormFieldState<string> = yield select(selectSubsidyApplicationId);
  const salesAgreementId: string = yield select(selectSalesAgreementId);
  const offerId: string = yield select(selectPlanOfferId);

  yield put(creditVerificationActions.setIsLoading(true));
  try {
    const {
      data: { performCreditCheck: results },
    }: PerformCreditCheckPayload = yield call<any>(performCreditCheckAsync, {
      partyId,
      cartId,
      productId: planId,
      lastFourOfSSN,
      taxId,
      dateOfBirth,
      serviceAddress,
      coordinates,
      network: updateCreditVerification,
      dealerId,
      partnerId,
      subsidyApplicationId: subsidyApplicationId.value,
      salesAgreementId,
      offerId,
    });
    if (!results.isEligible) {
      yield put(appActions.setModalVisible(Modals.CAE));
    } else {
      if (
        results.creditTag !== null &&
        results.creditTag === CREDIT_CHECK_ELIGIBILITY_VALUE &&
        isSSNMandatory &&
        displaySSNMandatoryModal
      ) {
        yield put(appActions.setModalVisible(Modals.MANDATORY_SSN));
      } else {
        if (results?.installationFee !== null) {
          yield put(cartActions.setOnetimeTotalWithPromo(results.installationFee! - onetimeDiscount));
          yield put(cartActions.setOnetimeTotalWithoutPromo(results.installationFee!));
        }
        if (
          results?.feesAndDiscounts !== null &&
          results?.feesAndDiscounts !== undefined &&
          results.feesAndDiscounts?.length > 0
        ) {
          yield put(cartActions.setFeesAndDiscounts(results.feesAndDiscounts));
        }
        yield put(creditVerificationActions.setCreditCheckComplete(true));
        yield put(appActions.setDisplayCalculatedLaterCartLabel(false));
        yield put(navActions.next());
        yield put(paymentInformationActions.getTaxCodes());
        yield put(appActions.setIsPersonalInformationComplete(true));
      }
    }
    yield put(appActions.setCreditCheckResults([{ name: CreditTag.DEFAULT, value: results.creditTag || '' }]));
  } catch (e: any) {
    clientLogger('createCustomerSaga: ', e);
    if (isVermontCustomer) yield put(navActions.routeUserTo(Routes.PERSONAL_INFORMATION));

    if (e.message === 'Restricted contract terms')
      yield put(
        appActions.setModalVisibleOverride({
          modal: Modals.ERROR,
          overrideBody: i18n.t('signup:CreditCheck.restrictedContract.body'),
          overrideHeader: i18n.t('signup:CreditCheck.restrictedContract.title'),
        }),
      );
    else {
      yield put(
        appActions.setErrorRetryAction({ type: creditVerificationActions.checkCustomerCredit.type, payload: null }),
      );
      yield put(appActions.setModalVisible(Modals.ERROR));
    }
  } finally {
    yield put(creditVerificationActions.updateCreditVerification(false));
    yield put(appActions.setIsCheckoutButtonDisabled(false));
    yield put(creditVerificationActions.setIsLoading(false));
    // need to handle this for VT customers since the routing to credit verification is skipped.
    // if it fails, we don't clear personal information loading like we would when
    // routing to credit verification
    yield put(personalInformationActions.setIsPersonalInformationLoading(false));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* creditVerificationSaga(): Generator {
  yield takeLatest(creditVerificationActions.checkCustomerCredit.type, benchmark(checkCustomerCreditSaga));
}
