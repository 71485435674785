import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Surface, Txt } from '@vst/beam';

import { ContentProvider, Header } from '@buy-viasat/react';
import { selectAccessToken } from '@buy-viasat/redux/src/auth';
import { serviceabilityActions } from '@buy-viasat/redux/src/serviceability';
import {
  appActions,
  CustomerType,
  selectCustomerType,
  selectFeatureFlags,
  selectUrlParams,
} from '@buy-viasat/redux/src/app';

import '../services/i18n';
import i18n from '../services/i18n';
import styles from './serviceability.module.scss';

import FormAddress from '../components/FormAddress';
import { selectEnv } from '@buy-viasat/redux/src/env';
import { AemFeature, createAemUrl } from '@buy-viasat/utils';
import { navActions, Routes } from '@buy-viasat/redux/src/navigator';

export function Serviceability() {
  const dispatch = useDispatch();
  const { t } = useTranslation(['serviceability', 'beamCommon']);
  const accessToken = useSelector(selectAccessToken);
  const customerType = useSelector(selectCustomerType);
  const { displayPhoneNumber, enablingClosingOffersInBV } = useSelector(selectFeatureFlags);
  const { aemUrl } = useSelector(selectEnv);
  const { offerId } = useSelector(selectUrlParams);

  const isBusinessUser = customerType === CustomerType.BUSINESS;
  const isXFDisplayed = enablingClosingOffersInBV && offerId;

  useEffect(() => {
    window.document.title = t('serviceability.meta.title');
    document.querySelector('meta[name="description"]')?.setAttribute('content', t('serviceability.meta.description'));
    dispatch(serviceabilityActions.onServiceabilityMount());
  }, [dispatch, t]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (!queryParams.has('address')) return;

    const addressObj = JSON.parse(queryParams.get('address')!);

    const address = {
      addressLines: addressObj.addressLines,
      municipality: addressObj.municipality,
      region: addressObj.region,
      postalCode: addressObj.postalCode,
      countryCode: addressObj.countryCode,
      houseNumber: addressObj.houseNumber,
    };

    setTimeout(() => {
      dispatch(serviceabilityActions.setServiceAddress(address));
      dispatch(
        serviceabilityActions.setCoordinates({
          latitude: addressObj.coordinates.latitude,
          longitude: addressObj.coordinates.longitude,
        }),
      );

      dispatch(
        serviceabilityActions.setScrubLocation({
          address,
          coordinates: {
            latitude: addressObj.coordinates.latitude,
            longitude: addressObj.coordinates.longitude,
          },
        }),
      );
      dispatch(appActions.resetPartyId());
      dispatch(navActions.routeUserTo(Routes.PLAN));
    });
  }, [dispatch]);

  useEffect(() => {
    if (accessToken) {
      dispatch(serviceabilityActions.setIsServiceabilityLoading(false));
    }
  }, [dispatch, accessToken]);

  return (
    <div className={styles['root']}>
      <Surface>
        <Container fluidUntil={'xl'} className={styles['header']}>
          <Row edge2edge>
            <Col>
              <Header
                displayPhoneNumber={displayPhoneNumber}
                phoneNumber={t('common.header.phoneNumber.text')}
                linkUrl={t('common.header.logo.url')}
                linkPrefix={t('common.header.phoneNumber.prefix')}
                modalTitle={t('common.header.modal.title')}
                modalDescription={t('common.header.modal.description')}
                modalPrimaryButtonText={t('common.header.modal.button.primaryLabel')}
              />
            </Col>
          </Row>
        </Container>
        <div className={styles['borderBottom']}></div>
      </Surface>
      <Surface variant="secondary" mx={{ lg: '48px' }} className={styles['main']}>
        <Container fluidUntil="md" className={styles['content']} pb={{ md: '40px' }}>
          <Row>
            <Col lg={{ span: 10, offset: 1 }}>
              <Surface
                variant={'secondary'}
                pt={isXFDisplayed ? '64px' : undefined}
                pb={isXFDisplayed ? '24px' : undefined}
              >
                {isXFDisplayed && (
                  <ContentProvider
                    id={AemFeature.CLOSING_OFFERS}
                    src={createAemUrl(
                      aemUrl,
                      i18n.language,
                      AemFeature.CLOSING_OFFERS,
                      '',
                      customerType,
                      false,
                      '',
                      '',
                      offerId,
                    )}
                  />
                )}
              </Surface>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <Surface className={styles['heading']} pt="28px" pb="32px" px="16px" mb="8px">
                <Txt variant="heading3" data-testid="serviceabilityHeadlineTitle" className={styles['heading-text']}>
                  {isBusinessUser ? t('serviceability.SMB.headline.title') : t('serviceability.headline.title')}
                </Txt>
              </Surface>
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <Surface radius="16px" className={styles['card']}>
                <FormAddress />
              </Surface>
            </Col>
          </Row>
        </Container>
      </Surface>
    </div>
  );
}

export default Serviceability;
