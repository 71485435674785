import { memo, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { planActions } from '@buy-viasat/redux/src/plan';

type ContentProviderProps = {
  src: string;
  onLoad?: () => void;
  id?: string;
  isLoading?: boolean;
};

export const ContentProvider = (props: ContentProviderProps) => {
  const { src, onLoad, id, isLoading } = props;
  const divRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!src) return;

    const getContent = (res: Response): Promise<string> => {
      if (res.status === 200) {
        return res.text();
      } else {
        if (!isLoading) {
          dispatch(planActions.isFragmentUnpublished(id ?? ''));
        }
        const errorMessage = `AEM Error Status Code ${res.status}`;
        console.error(errorMessage);
        throw new Error(errorMessage);
      }
    };

    const setContent = (htmlContent: string): void => {
      const slot = divRef?.current;
      if (!htmlContent || !slot) {
        return;
      }
      const fragment = document.createRange().createContextualFragment(htmlContent);
      slot.innerHTML = '';
      slot.append(fragment);
    };

    fetch(src)
      .then(getContent)
      .then(setContent)
      .catch((error) => {
        console.error('Error in promise chain:', error);
      })
      .finally(() => {
        if (onLoad) onLoad();
      });
  }, [src, dispatch, onLoad, isLoading, id]);

  return <div ref={divRef} id={id}></div>;
};

export default memo(ContentProvider);
