import { useDispatch, useSelector } from 'react-redux';
import { ComponentProps, FormEventHandler, MouseEvent, PropsWithChildren, useEffect } from 'react';
import { Col, Container, Form, Row, Surface, useBreakpoints } from '@vst/beam';

import { numberOfSteps } from '@buy-viasat/utils';
import { FormValue } from '@vst/shared/src/lib/form';
import {
  appActions,
  selectFeatureFlags,
  selectIsCartVisible,
  selectSalesFlowDefinition,
  useFeatureFlags,
} from '@buy-viasat/redux/src/app';
import { selectAvailableAddons } from '@buy-viasat/redux/src/addons';
import { selectIsVermontCustomer } from '@buy-viasat/redux/src/serviceability';
import { selectIsSubsidyCheckboxChecked } from '@buy-viasat/redux/src/personal-information';
import {
  Routes,
  ScheduleInstallLocations,
  selectCurrentAppRoute,
  selectStepNumber,
} from '@buy-viasat/redux/src/navigator';

import styles from './checkoutLayout.module.scss';
import { CheckoutCartHeader } from '../CheckoutCart/CheckoutCartHeader';
import { CheckoutCart, Divider, Header, LoadingSpinner, Navigator, ProgressBar } from '../..';
import { LoadingInstallationDates } from '../LoadingSpinner/loadingInstallationDates';
import { useTranslation } from 'react-i18next';

interface CheckoutLayoutProps {
  loading?: boolean;
  loadingText?: string;
  className?: string;
  headerProps: ComponentProps<typeof Header>;
  onConfirm: FormEventHandler<HTMLFormElement>;
  checkoutDisclosure?: React.ReactNode | null;
  progressProps: Omit<ComponentProps<typeof ProgressBar>, 'initialStep' | 'totalNumberOfSteps'>;
  cartProps: Omit<ComponentProps<typeof CheckoutCart>, 'displayMobileCart'>;
  navigatorProps: ComponentProps<typeof Navigator>;
  initialValues?: Record<string, FormValue>;
  handleSkipScheduleInstallationClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const CheckoutActions = (props: Pick<CheckoutLayoutProps, 'checkoutDisclosure' | 'navigatorProps' | 'loading'>) => {
  const { checkoutDisclosure, navigatorProps, loading } = props;
  const { viewport } = useBreakpoints();

  return (
    <>
      {checkoutDisclosure ? (
        <Surface pt={{ xs: '24px', md: '40px' }} pb={{ md: '24px', sm: '32px' }}>
          {checkoutDisclosure}
        </Surface>
      ) : null}
      <Surface>
        {viewport.largerOrWithin.md && <Divider position="outside-2" mt="24px" />}
        <Navigator {...navigatorProps} disabled={loading} noTopPadding={true} />
      </Surface>
    </>
  );
};

export const CheckoutLayout = (props: PropsWithChildren<CheckoutLayoutProps>) => {
  const {
    loading = false,
    loadingText = '',
    className = '',
    headerProps,
    progressProps,
    navigatorProps,
    checkoutDisclosure,
    children,
    onConfirm,
    initialValues,
    cartProps,
    handleSkipScheduleInstallationClick = () => null,
  } = props;

  const dispatch = useDispatch();
  const flags = useFeatureFlags();

  const { viewport } = useBreakpoints();

  const stepNumber = useSelector(selectStepNumber);

  const availableAddons = useSelector(selectAvailableAddons);
  const isVermontCustomer = useSelector(selectIsVermontCustomer);
  const isSubsidyCheckboxChecked = useSelector(selectIsSubsidyCheckboxChecked);
  const { displayCreditCheckPage, enablePlaceMyOrderFromPaymentPage } = useSelector(selectFeatureFlags);
  const displayMobileCart = useSelector(selectIsCartVisible);
  const { shouldPlaceOrderFromPaymentPage } = useSelector(selectSalesFlowDefinition);

  const hasAddons = flags.displayAddonsPage && !!availableAddons?.length;
  const shouldSkipScheduleInstallInCart =
    flags.scheduleInstallLocation === ScheduleInstallLocations.OrderSummary ||
    flags.scheduleInstallLocation === ScheduleInstallLocations.None;

  const canPlaceMyOrderFromPaymentPage = enablePlaceMyOrderFromPaymentPage && shouldPlaceOrderFromPaymentPage;

  const totalNumberOfSteps = numberOfSteps(
    shouldSkipScheduleInstallInCart,
    isVermontCustomer,
    isSubsidyCheckboxChecked,
    hasAddons,
    displayCreditCheckPage,
    canPlaceMyOrderFromPaymentPage,
  );

  const onCheckoutCartHeaderClick = () => dispatch(appActions.setIsCartVisible(!displayMobileCart));
  const { t } = useTranslation(['scheduleInstallation']);
  const currentRoute = useSelector(selectCurrentAppRoute);
  const isLookingForInstallationDates = loading && currentRoute == Routes.SCHEDULE_INSTALLATION;

  useEffect(() => {
    dispatch(appActions.setIsCartVisible(false));
  }, [dispatch]);

  return (
    <Surface variant="secondary" className={styles['root']}>
      <Surface className={styles['header']} data-testid="header">
        <Container fluidUntil={'xl'} className={styles['headerContainer']}>
          <Row edge2edge>
            <Col>
              <Header {...headerProps} />
            </Col>
          </Row>
        </Container>
      </Surface>

      <Form onSubmit={onConfirm} id="checkoutForm" initialValues={initialValues}>
        <Surface className={styles['main']} variant="secondary" mx={{ lg: '48px' }}>
          {viewport.smallerOrWithin.sm && (
            <>
              <CheckoutCartHeader
                onClick={onCheckoutCartHeaderClick}
                displayMobileCart={displayMobileCart}
                phraseDueMonthly={cartProps.phraseKeys.mobile.dueMonthlyTitle}
                phraseDueToday={cartProps.phraseKeys.mobile.dueTodayTitle}
              />
              <CheckoutCart displayMobileCart={displayMobileCart} {...cartProps} />
            </>
          )}
          <ProgressBar initialStep={stepNumber} totalNumberOfSteps={totalNumberOfSteps} {...progressProps} />
          <Container fluidUntil="md" pb={{ md: '72px' }} pt={{ md: '8px' }}>
            <Row edge2edge>
              <Col lg={8}>
                <Surface
                  responsive
                  className={`${styles['content']} ${className} ${loading ? styles['loadingContent'] : ''}`}
                >
                  {isLookingForInstallationDates ? (
                    <LoadingInstallationDates
                      skipButtonText={t('scheduleInstallation.link.skipAndContinue')}
                      headingTitle={t('scheduleInstallation.subheading.title.selectDate')}
                      handleSkipLinkClick={handleSkipScheduleInstallationClick}
                    >
                      <LoadingSpinner size="large" text={loadingText} />
                    </LoadingInstallationDates>
                  ) : loading ? (
                    <LoadingSpinner size="large" text={loadingText} />
                  ) : null}
                  <Surface responsive className={loading ? styles['loading'] : styles['loaded']}>
                    <div>{children}</div>
                    {viewport.largerThan.sm ? (
                      <CheckoutActions
                        navigatorProps={navigatorProps}
                        checkoutDisclosure={checkoutDisclosure}
                        loading={loading}
                      />
                    ) : null}
                  </Surface>
                </Surface>
              </Col>
              <Col lg={4} className={styles['asideContainer']}>
                <Surface responsive className={styles['aside']} data-testid="cart">
                  {viewport.smallerOrWithin.sm ? (
                    <CheckoutActions
                      navigatorProps={navigatorProps}
                      checkoutDisclosure={checkoutDisclosure}
                      loading={loading}
                    />
                  ) : (
                    <CheckoutCart displayMobileCart={displayMobileCart} {...cartProps} />
                  )}
                </Surface>
              </Col>
            </Row>
          </Container>
        </Surface>
      </Form>
    </Surface>
  );
};

export default CheckoutLayout;
