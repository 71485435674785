import { TaxCode } from '@buy-viasat/types/build/bv';
import { PayloadAction } from '@reduxjs/toolkit';
import { RESET_STATE_ACTION } from '../types';
import { createSlice } from '../utils';
import {
  AuthVoidTransaction,
  IFrameRadioButton,
  initialPaymentInformationState,
  PaymentInformationState,
  PaymentOnFile,
  PaymentType,
  QueryPaymentOnFile,
} from './types';

const paymentInformationSlice = createSlice({
  name: 'paymentInformation',
  initialState: initialPaymentInformationState,
  reducers: {
    getTaxCodes(state: PaymentInformationState): void {
      state.isPaymentInformationLoading = true;
    },
    onEditBillingAddress(state: PaymentInformationState): void {
      state.isPaymentInformationLoading = false;
    },
    addBillingAccount(state: PaymentInformationState, action: PayloadAction<string[]>): void {},
    updateBillingAccount(): void {},
    createVPPTransaction(state: PaymentInformationState, action: PayloadAction<string>): void {
      state.vppCardNameIsValid = false;
      state.vppCardNumberIsValid = false;
      state.vppCardCVVIsValid = false;
      state.vppCardExpirationIsValid = false;
    },
    // SETTERS START
    resetTransactionId(state: PaymentInformationState): void {
      state.transactionId = initialPaymentInformationState.transactionId;
    },
    setIFrameRadioButton(state: PaymentInformationState, action: PayloadAction<IFrameRadioButton>): void {
      state.iFrameRadioButton = action.payload;
    },
    setIsPaymentInformationLoading(state: PaymentInformationState, action: PayloadAction<boolean>): void {
      state.isPaymentInformationLoading = action.payload;
      if (!action.payload) {
        state.isPaymentInformationLoadingStartTime = 0;
      }
    },
    setIsPaymentInformationLoadingStartTime(state: PaymentInformationState, action: PayloadAction<number>): void {
      state.isPaymentInformationLoadingStartTime = action.payload;
    },
    setIsPaymentInformationValid(state: PaymentInformationState, action: PayloadAction<boolean>): void {
      state.isPaymentInformationValid = action.payload;
    },
    setTransactionId(state: PaymentInformationState, action: PayloadAction<string>): void {
      state.transactionId = action.payload;
    },
    setSpbBillingAccountId(state: PaymentInformationState, action: PayloadAction<string>): void {
      state.spbBillingAccountId = action.payload;
    },
    setRegulatedSpbBillingAccountId(state: PaymentInformationState, action: PayloadAction<string>): void {
      state.regulatedSPBBillingAccountId = action.payload;
    },
    setNonRegulatedSpbBillingAccountId(state: PaymentInformationState, action: PayloadAction<string>): void {
      state.nonRegulatedSPBBillingAccountId = action.payload;
    },
    setSmbBillingAccountId(state: PaymentInformationState, action: PayloadAction<string>): void {
      state.smbBillingAccountId = action.payload;
    },
    setTaxCode(state: PaymentInformationState, action: PayloadAction<TaxCode>): void {
      state.taxCode = action.payload;
    },
    setSameAsPrimaryContact(state: PaymentInformationState, action: PayloadAction<boolean>): void {
      state.sameAsPrimaryContact = action.payload;
    },
    setVPPCardType(state: PaymentInformationState, action: PayloadAction<string>): void {
      state.vppCardType = action.payload;
    },
    setVppCardNameIsValid(state: PaymentInformationState, action: PayloadAction<boolean>): void {
      state.vppCardNameIsValid = action.payload;
    },
    setVppCardNumberIsValid(state: PaymentInformationState, action: PayloadAction<boolean>): void {
      state.vppCardNumberIsValid = action.payload;
    },
    setVppCardCVVIsValid(state: PaymentInformationState, action: PayloadAction<boolean>): void {
      state.vppCardCVVIsValid = action.payload;
    },
    setVppCardExpirationIsValid(state: PaymentInformationState, action: PayloadAction<boolean>): void {
      state.vppCardExpirationIsValid = action.payload;
    },
    setVppPostMessageSuccess(state: PaymentInformationState, action: PayloadAction<string>): void {
      state.vppPostMessageSuccess = true;
      state.isPaymentInformationLoading = false;
      state.paymentOnFileId = action.payload;
    },
    setVPPPostMessageFailure(state: PaymentInformationState): void {
      state.vppPostMessageSuccess = false;
      state.isPaymentInformationLoading = false;
    },
    setVppSubmitSaleSuccess(state: PaymentInformationState, action: PayloadAction<boolean>): void {
      state.vppSubmitSaleSuccess = action.payload;
    },
    setVPPPaymentType(state: PaymentInformationState, action: PayloadAction<PaymentType>): void {
      state.vppPaymentType = action.payload;
    },
    createVPPAuthVoidTransaction(state: PaymentInformationState, action: PayloadAction<AuthVoidTransaction>): void {
      state.paymentOnFileId = action.payload.paymentOnFileId;
    },
    setPaymentOnFileRequested(state: PaymentInformationState, action: PayloadAction<QueryPaymentOnFile>): void {
      state.systemName = action.payload.systemName;
      state.customerRef = action.payload.customerRef;
    },
    setPaymentOnFileSuccess(state: PaymentInformationState, action: PayloadAction<PaymentOnFile>): void {
      state.paymentOnFile = action.payload;
    },
    setVpp3DSMode(state: PaymentInformationState, action: PayloadAction<boolean>): void {
      state.vpp3DSMode = action.payload;
    },
    setIsIFrameLoading(state: PaymentInformationState, action: PayloadAction<boolean>): void {
      state.isIframeLoading = action.payload;
    },
    clearBillingData(state: PaymentInformationState) {},
    //  SETTERS END
  },
  extraReducers: {
    [RESET_STATE_ACTION.type]: (): PaymentInformationState => initialPaymentInformationState,
  },
});

export const {
  actions: paymentInformationActions,
  reducer: paymentInformationReducer,
  name: paymentInformationKey,
} = paymentInformationSlice;
