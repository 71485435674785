import { Country } from '@buy-viasat/types/build/bv';
import {
  DateFormat,
  dateRegex,
  emailRegex,
  lastFourRegex,
  subsidyApplicationIdRegex,
  taxIdCountryRegex,
} from '@buy-viasat/utils';
import i18n from '@buy-viasat/utils/src/lib/config/i18n';
import { ValidationPayload } from '../types';
import {
  convertDateString,
  dateToStringFormat,
  getAge,
  isDateValid,
  isDayValid,
  isLeapYear,
  isMonthValid,
  isYearValid,
} from './utils';

export const createFieldRequiredPayload = (hintText: string): ValidationPayload => {
  return { valid: false, hintText };
};

export const mobileValidNumber = (): ValidationPayload => {
  return { valid: true, hintText: '' };
};

export const FIELD_REQUIRED_PAYLOAD = { valid: false, hintText: i18n.t('components:thisFieldIsRequired') };

export const validateWithRegex = (value: string, regex: RegExp): boolean => regex.test(value);

export const validateEmail = (value: string | undefined | null): ValidationPayload =>
  value
    ? { valid: validateWithRegex(value, emailRegex), hintText: i18n.t('components:accountDetails.emailIsInvalid') }
    : createFieldRequiredPayload(i18n.t('components:accountDetails.emailRequired'));

export const validatePhone = (value: string | undefined | null, phoneRegex: RegExp): ValidationPayload =>
  value
    ? {
        valid: validateWithRegex(value, phoneRegex),
        hintText: i18n.t('components:accountDetails.phoneIsInvalid'),
      }
    : createFieldRequiredPayload(i18n.t('components:accountDetails.phoneNumberRequired'));

export const validateMobileNumber = (value: string | undefined | null, mobileRegex: RegExp): ValidationPayload =>
  value
    ? {
        valid: validateWithRegex(value, mobileRegex),
        hintText: i18n.t('components:accountDetails.mobileNumberIsInvalid'),
      }
    : mobileValidNumber();

export const validateFirstName = (value: string | undefined | null) =>
  validateRequired(value, i18n.t('components:accountDetails.firstNameRequired'));

export const validateLastName = (value: string | undefined | null) =>
  validateRequired(value, i18n.t('components:accountDetails.lastNameRequired'));

export const validateLastFour = (value: string | undefined | null): ValidationPayload =>
  value
    ? {
        valid: validateWithRegex(value, lastFourRegex),
        hintText: i18n.t('components:creditCheck.ssnLastFourIsInvalid'),
      }
    : { valid: true, hintText: '' };

export const validateSSN = (value: string | undefined | null): ValidationPayload =>
  value
    ? {
        valid: validateWithRegex(value, lastFourRegex),
        hintText: i18n.t('components:creditCheck.ssnLastFourIsRequired'),
      }
    : createFieldRequiredPayload(i18n.t('components:creditCheck.ssnLastFourIsRequired'));

export const validateTaxId = (value: string | undefined | null): ValidationPayload =>
  value
    ? {
        valid: validateWithRegex(value, taxIdCountryRegex[Country.BR]),
        hintText: i18n.t('components:creditCheck.ssnLastFourIsInvalid'),
      }
    : createFieldRequiredPayload(i18n.t('components:creditCheck.taxIdIsRequired'));

export const validateMonth = (value: string | undefined | null): ValidationPayload =>
  value
    ? {
        valid: isMonthValid(value),
        hintText: i18n.t('components:creditCheck.monthIsInvalid'),
      }
    : createFieldRequiredPayload(i18n.t('components:creditCheck.monthIsRequired'));

export const validateDay = (value: string | undefined | null): ValidationPayload =>
  value
    ? {
        valid: isDayValid(value),
        hintText: i18n.t('components:creditCheck.dayIsInvalid'),
      }
    : createFieldRequiredPayload(i18n.t('components:creditCheck.dayIsRequired'));

export const validateYear = (value: string | undefined | null): ValidationPayload =>
  value
    ? {
        valid: isYearValid(value),
        hintText: i18n.t('components:creditCheck.yearIsInvalid'),
      }
    : createFieldRequiredPayload(i18n.t('components:creditCheck.yearIsRequired'));

export const validateSubsidyApplicationId = (value: string | undefined | null): ValidationPayload =>
  value
    ? {
        valid: validateWithRegex(value, subsidyApplicationIdRegex),
        hintText: i18n.t('signup:subsidy.applicationIdInvalid'),
      }
    : createFieldRequiredPayload(i18n.t('signup:subsidy.applicationIdRequired'));

export const validateRequired = (value: string | undefined | null, hintText: string | null = null): ValidationPayload =>
  value?.trim()
    ? { valid: true, hintText: '' }
    : hintText
    ? createFieldRequiredPayload(hintText)
    : FIELD_REQUIRED_PAYLOAD;

export const dateOfBirthValidation = (
  month: string | undefined | null,
  day: string | undefined | null,
  year: string | undefined | null,
  today: Date,
): ValidationPayload => {
  const dateObj = convertDateString(month, day, year);
  const age = getAge(dateObj, today);
  const dateObject = dateToStringFormat(month, day, year);
  const validateWithRegexResult = validateWithRegex(dateObject, dateRegex(DateFormat.MDY));
  const isLeapYearValue = isLeapYear(year ?? '');

  if (!validateWithRegexResult) {
    return { valid: false, hintText: i18n.t('components:creditCheck.dateIsInvalid') };
  } else if (age < 18) {
    return { valid: false, hintText: i18n.t('components:creditCheck.mustBeEighteenYearsOld') };
  } else if (age > 110) {
    return { valid: false, hintText: i18n.t('components:creditCheck.dateIsInvalid') };
  } else if (!isDateValid(day ?? '', month ?? '', isLeapYearValue)) {
    return { valid: false, hintText: i18n.t('components:creditCheck.dateIsInvalid') };
  } else {
    return { valid: true, hintText: '' };
  }
};
